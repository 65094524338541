import Utils from '../helpers/Utils'

export default class Forms {
  constructor () {
    this.passwordIcons = document.querySelectorAll('.o-js-password-icon')
  }

  init () {
    if (this.passwordIcons.length) {
      this.handleClickOnPasswordIcon()
    }
  }

  handleClickOnPasswordIcon () {
    Utils.forEach(this.passwordIcons, (index, el) => {
      el.addEventListener('click', () => {
        const passwordInput = el.parentNode.querySelector('.o-js-password-input')

        if (passwordInput.getAttribute('type') === 'password') {
          passwordInput.setAttribute('type', 'text')
        } else {
          passwordInput.setAttribute('type', 'password')
        }
      })
    })
  }
}
