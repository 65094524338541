import L from 'leaflet'

export default class Map {
  constructor () {
    this.el = document.querySelector('.o-js-map')
    this.listPlaces = document.querySelectorAll('.o-js-map-place')
    this.map = null
    this.language = document.querySelector('html').getAttribute('lang')
  }

  init () {
    this.initMap()
    this.addMarkers()
  }

  initMap () {
    let center = L.latLng(this.el.dataset.lat, this.el.dataset.lng)
    let mapId = this.el.getAttribute('id')

    this.map = L.map(mapId, {
      center: center,
      zoom: 5
    })

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.language, {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map)
  }

  addMarkers () {
    for (let i = 0; i < this.listPlaces.length; i++) {
      let placeLatLng = L.latLng(this.listPlaces[i].dataset.lat, this.listPlaces[i].dataset.lng)
      let placeIcon = L.icon({
        iconUrl: this.listPlaces[i].dataset.icon,
        iconSize: [28, 28],
        iconAnchor: [14, 14],
      })

      L.marker(placeLatLng, { icon: placeIcon }).addTo(this.map)
    }
  }
}
