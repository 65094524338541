import EventEmitter from '../../helpers/EventEmitter'
import Reel from './Reel'

/**
 * @class ReelManager
 */
export default class ReelManager {
  constructor (el, id) {
    this.el = el
    this.index = 0
    this.posY = null
    this.reel = new Reel(this.el, id)
    this.button = document.querySelector('.o-js-slot-launch')
    this.isFirst = false
    this.isPinned = false
    this.pagesDisplayed = 0
  }

  init () {
    // Event listeners on el
    this.listenToEvents()

    // Init reel
    this.reel.init()
  }

  listenToEvents () {
    this.el.addEventListener('mousedown', (e) => {
      this.lock(e)
    })
    this.el.addEventListener('touchstart', (e) => {
      this.lock(e)
    })

    this.el.addEventListener('touchmove', (e) => {
      e.preventDefault()
    })

    this.el.addEventListener('mouseup', (e) => {
      this.move(e)
    })
    this.el.addEventListener('touchend', (e) => {
      this.move(e)
    })
  }

  /**
   * @function displayResults
   * Make reel do one loop
   * Create new slots elements and append them with interval
   * Move reel to middle element
   * Append all new slots and remove old ones
   * Call @functions from @class Reel
   *
   * @returns {Promise}
   */
  displayResults (data) {
    return new Promise((resolve) => {
      this.reel.goToIndex(this.reel.slotsPerReel, this.reel.loopDuration, () => {
        this.reel.createNewSlots(data, data.length, false)
        this.reel.setIndex(0)
        this.reel.appendNewSlotsInterval().then(() => {
          this.reel.appendAllNewSlots()
          this.reel.removeOldSlots()
          resolve()
        })
        this.reel.goToIndex(this.reel.midIndex, this.reel.loopDuration * this.reel.midIndex / this.reel.slotsPerReel, () => {
          this.reel.selectedIndex = this.reel.midIndex
        })
      })
    })
  }

  /**
   * @function unify
   * Unify the touch and click cases
   */
  unify (e) {
    return e.changedTouches ? e.changedTouches[0] : e
  }

  /**
   * @function lock
   * Get drag starting position
   */
  lock (e) {
    this.posY = this.unify(e).clientY
  }

  /**
   * @function move
   * Determine direction drag and emit goToNextSlot or goToPrevSlot events
   */
  move (e) {
    if ((this.posY || this.posY === 0) && !this.isPinned) {
      let dy = this.unify(e).clientY - this.posY
      let sign = Math.sign(dy)

      if ((this.index > 0 || sign < 0) && Math.abs(dy) > 10) {
        if (this.reel.selectedIndex > 0) {
          EventEmitter.emit('goToNextSlot')
        }
      } else if ((this.index < this.reel.slotsPerReel - 1 || sign > 0) && Math.abs(dy) > 10) {
        if (this.reel.selectedIndex < this.reel.slots.length - 1) {
          EventEmitter.emit('goToPrevSlot')
        }
      }
    }
  }

  /**
   * @function onResize
   * Call @function onResize from @class Reel
   */
  onResize () {
    this.reel.onResize()
  }
}
