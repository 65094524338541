import 'jquery-typeahead'

import Utils from '../helpers/Utils'

export default class Autocomplete {
  constructor () {
    this.autocompleteHome = document.querySelectorAll('.o-js-autocomplete_home')
    this.autocompleteGrapes = $('.o-js-autocomplete_grapes')
    this.callback = []
    this.event = new Event('typeahead-update')
  }

  init () {
    // Autocomplete home search fields
    // See doc/index.md part index.md#recherche-par-saisie-de-texte
    let _this = this
    let autocompleteEmptyMessageStart = document.querySelector('.o-js-autocomplete-empty-message-start')
    let autocompleteEmptyMessageEnd = document.querySelector('.o-js-autocomplete-empty-message-end')

    if (this.autocompleteHome.length) {
      Utils.forEach(this.autocompleteHome, (index, autocompleteHome) => {
        $(autocompleteHome).typeahead({
          dynamic: true,
          filter: false,
          maxItem: false,
          hint: true,
          highlight: true,
          minLength: 3,
          source: {
            record: {
              ajax: {
                method: 'GET',
                url: $(autocompleteHome).data('url'),
                data: {
                  term: '{{query}}'
                }
              }
            }
          },
          display: 'name',
          callback: {
            onSearch: function (node, query) {
              if (node.attr('data-type') === 'recipe') {
                const nodeId = node.attr('id')
                const hiddenFieldString = nodeId.replace(/_s_fake/, '_s')
                document.getElementById(hiddenFieldString).value = query
              }
            },
            onLayoutBuiltBefore: function (node, query, result, resultHTML) {
              result.forEach((r, index) => {
                if (r.keyword_by_prefix) {
                  const item = resultHTML.find('.typeahead__item[data-index="' + index + '"]')
                  const itemDisplay = item.find('.typeahead__display')
                  const itemText = itemDisplay.text()
                  const keywordIndex = itemText.indexOf(r.keyword_by_prefix)
                  const newItemText = itemText.slice(0, keywordIndex) + '<strong>' + itemText.slice(keywordIndex, r.keyword_by_prefix.length) + '</strong>' + itemText.slice(keywordIndex + r.keyword_by_prefix.length)
                  itemDisplay.html(newItemText)
                }
              })
            },
            onClickBefore: function (node, li, item, event) {
              const itemId = item.id
              if (itemId) {
                const nodeId = node.attr('id')
                const hiddenFieldId = (node.attr('data-type') === 'recipe') ? nodeId.replace(/_s_fake/, '_id') : nodeId.replace(/_s/, '_id')
                document.getElementById(hiddenFieldId).value = itemId

                if (node.attr('data-type') === 'recipe') {
                  const hiddenFieldString = nodeId.replace(/_s_fake/, '_s')
                  document.getElementById(hiddenFieldString).value = item.name_strict
                }
              }
            },
            onClickAfter: function () {
              autocompleteHome.dispatchEvent(_this.event)
              $('input').blur()
            },
            onCancel: function (node, event) {
              autocompleteHome.dispatchEvent(_this.event)
            }
          },
          emptyTemplate: function (query) {
            if (query.length > 3) {
              if (autocompleteHome.dataset.type === 'recipe') {
                return autocompleteEmptyMessageStart.dataset.textRecipe + '"' + query + '"'
              } else {
                return autocompleteEmptyMessageStart.dataset.textWine + '"' + query + '", ' + autocompleteEmptyMessageEnd.dataset.textWine
              }
            }
          }
        })
      })
    }

    // Autocomplete grapes modal
    if (this.autocompleteGrapes.length) {
      let grapes = this.autocompleteGrapes.data('grapes')
      let grapesItems = $('.o-js-autocomplete_grapes-item')
      let grapesEmptyMessage = $('.o-js-grapes_empty_message')

      this.autocompleteGrapes.typeahead({
        dynamic: true,
        accent: true,
        filter: true,
        maxItem: false,
        hint: true,
        highlight: true,
        minLength: 1,
        source: {
          grapes: {
            data: grapes,
            display: 'name'
          }
        },
        callback: {
          onResult: function(node, query, result, resultCount, resultCountPerGroup) {
            if (resultCount > 0) {
              grapesEmptyMessage.hide()
              let results = []

              for (var i = 0; i < result.length; i++) {
                results.push(result[i].id)
              }

              grapesItems.each(function() {
                if ($.inArray($(this).attr('id'), results) !== -1) {
                  $(this).show()
                } else {
                  $(this).hide()
                }
              })
            } else {
              if (query !== '') {
                grapesItems.hide()
                grapesEmptyMessage.show()
              }
            }
          },
          onCancel: function(node, event) {
            grapesItems.each(function() {
              $(this).show()
            })
          }
        }
      })
    }
  }

  listenToKeyPress (callback) {
    Utils.forEach(this.autocompleteHome, (index, autocompleteHome) => {
      autocompleteHome.addEventListener('keypress', function (e) {
        if (e.which !== 0) {
          callback(autocompleteHome, this.value.length)
        }
      })
    })
  }

  listenToTypeaheadSelect (callback) {
    Utils.forEach(this.autocompleteHome, (index, autocompleteHome) => {
      autocompleteHome.addEventListener('typeahead-update', function (e) {
        callback(autocompleteHome)
      })
    })
  }
}
