import Utils from '../helpers/Utils'

export default class Icons {
  init () {
    let screenAlertCookie = Utils.getCookie('screen_alert')
    let screenAlertMessages = document.querySelectorAll('.o-js-screen_alert')

    screenAlertMessages.forEach((screenAlertMessage) => {
      if (screenAlertMessage && screenAlertCookie !== 'false') {
        screenAlertMessage.classList.remove('o-hidden')
      }
    })

    let allergenCookie = Utils.getCookie('allergen')
    let allergenIcon = document.querySelector('.o-sheet__content__icons span[data-tag="allergen"]')
    if (allergenIcon && allergenCookie !== 'false') {
      allergenIcon.parentNode.classList.remove('o-hidden')
    }

    let trouNormandCookie = Utils.getCookie('trou_normand')
    let trouNormandIcon = document.querySelector('.o-sheet__content__icons span[data-tag="trou_normand"]')
    if (trouNormandIcon && trouNormandCookie !== 'false') {
      trouNormandIcon.parentNode.classList.remove('o-hidden')
    }

    let chabrotCookie = Utils.getCookie('chabrot')
    let chabrotIcon = document.querySelector('.o-sheet__content__icons span[data-tag="chabrot"]')
    if (chabrotIcon && chabrotCookie !== 'false') {
      chabrotIcon.parentNode.classList.remove('o-hidden')
    }
  }
}
