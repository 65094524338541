import Hammer from 'hammerjs'
import Utils from '../helpers/Utils'

export default class ToggleButtons {
  constructor () {
    this.els = document.querySelectorAll('.o-js-tgl')
  }

  init () {
    Utils.forEach(this.els, (index, el) => {
      this.listenToSwipe(el)
    })
  }

  listenToSwipe (el) {
    let hammer = new Hammer(el)
    let checkbox = el.previousElementSibling
    if (checkbox.classList.contains('o-js-tgl-checkbox')) {
      hammer.on('swipeleft', () => {
        if (checkbox.checked) {
          checkbox.checked = false
        }
      })

      hammer.on('swiperight', () => {
        if (!checkbox.checked) {
          checkbox.checked = true
        }
      })
    }
  }
}
