import { TweenMax, Power0, Power1 } from 'gsap/TweenMax'
import Utils from '../../helpers/Utils'

/**
 * @class Reel
 */
export default class Reel {
  constructor (swipeEl, id) {
    this.container = swipeEl
    this.identifier = id
    this.newSlots = []
    this.setHTMLElements()
    this.midIndex = Math.floor(this.slots.length / 2)

    this.selectedIndex = 0
    this.slotsPerReel = 6
    this.resultsPerPage = 5
    this.slotAngle = 360 / this.slotsPerReel
    this.slotHeight = this.ring.offsetHeight

    this.loopDuration = 0.5
  }

  setHTMLElements () {
    this.ring = this.container.querySelector('.o-js-slot-ring')
    this.slots = this.ring.querySelectorAll('.o-js-slot-el')
  }

  init () {
    this.positionSlots()
    this.setIndex(this.midIndex)
  }

  /**
   * @function positionSlots
   * Apply rotate transform to slot according to its index
   * Set slot opacity
   */
  positionSlots () {
    Utils.forEach(this.slots, (index, slot) => {
      this.setSlotTransform(slot, index * this.slotAngle)

      if (!this.slotShouldBeDisplayed(index)) {
        slot.style.opacity = 0
      }
    })
  }

  /**
   * @function getReelRadius
   * Calculate transformZ radius according to slotHeight and slotsPerReel @properties
   * @returns {float} radius
   */
  getReelRadius () {
    let radius = (this.slotHeight / 2) / Math.tan(Math.PI / this.slotsPerReel)

    return radius
  }

  /**
   * @function slotShouldBeDisplayed
   * Determine if slot should be displayed according to current index
   * And assuming we show only slotsPerReel items
   * @param {int} index
   * @returns {boolean}
   */
  slotShouldBeDisplayed (index) {
    if ((index <= this.midIndex && index >= this.midIndex - this.slotsPerReel / 2 + 1) || (index >= this.midIndex && index <= this.midIndex + this.slotsPerReel / 2)) {
      return true
    } else {
      return false
    }
  }

  /**
   * @function setIndex
   * Move ring angle to index without animation
   * @param {int} index
   * Set selectedIndex @property
   */
  setIndex (index) {
    let angle = this.slotAngle * index

    TweenMax.set(this.ring, {
      rotationX: -angle
    })

    this.selectedIndex = index
  }

  /**
   * @function goToIndex
   * Move ring angle to index with animation
   * @param {int} index
   * @param {float} duration
   * @param {@function} callback
   * Set selectedIndex @property
   */
  goToIndex (index, duration, callback = null) {
    let angle = this.slotAngle * index
    this.isAnimating = true

    TweenMax.to(this.ring, duration, {
      rotationX: '+=' + -angle,
      ease: Power0.easeNone,
      onComplete: () => {
        this.isAnimating = false
        if (callback) {
          callback()
        }
      }
    })

    this.selectedIndex = index
  }

  /**
   * @function animateOneLoop
   * Move ring angle with animation to do one loop
   * @param {float} duration
   * @param {@function} callback
   */
  animateOneLoop (duration, callback = null) {
    let angle = this.slotAngle * this.slotsPerReel

    this.isAnimating = true

    TweenMax.to(this.ring, duration, {
      rotationX: '+=' + -angle,
      ease: Power0.easeNone,
      onComplete: () => {
        this.isAnimating = false
        if (callback) {
          callback()
        }
      }
    })
  }

  /**
   * @function goToPrev
   * Move ring to prev index with animation
   * Hide / show slots assuming we show only slotsPerReel items
   * set selectedIndex @property
   */
  goToPrev () {
    if (!TweenMax.isTweening(this.ring)) {
      TweenMax.to(this.ring, 0.3, {
        rotationX: '+=' + -this.slotAngle,
        ease: Power1.easeOut
      })

      let indexesToHide = []
      let indexesToShow = []
      for (let i = 1; i <= this.slotsPerReel / 2; i++) {
        indexesToHide.push(this.selectedIndex - i)
        indexesToShow.push(this.selectedIndex + i)
      }

      this.setSlotsOpacityWithIndexes(0, indexesToHide)
      this.setSlotsOpacityWithIndexes(1, indexesToShow)

      this.selectedIndex += 1
    }
  }

  /**
   * @function goToNext
   * Move ring to next index with animation
   * Hide / show slots assuming we show only slotsPerReel items
   * set selectedIndex @property
   */
  goToNext () {
    if (!TweenMax.isTweening(this.ring)) {
      TweenMax.to(this.ring, 0.3, {
        rotationX: '+=' + this.slotAngle,
        ease: Power1.easeOut
      })

      let indexesToHide = []
      let indexesToShow = []
      for (let i = 1; i <= this.slotsPerReel / 2; i++) {
        indexesToHide.push(this.selectedIndex + i)
        indexesToShow.push(this.selectedIndex - i)
      }

      this.setSlotsOpacityWithIndexes(0, indexesToHide)
      this.setSlotsOpacityWithIndexes(1, indexesToShow)

      this.selectedIndex -= 1
    }
  }

  /**
   * @function createNewSlots
   * Create slot html element by looping through data array
   * Make sure to have mirror elements
   * @param {array} data
   */
  createNewSlots (data, nbEls) {
    this.newSlots = []

    for (let i = 0; i < data.length; i++) {
      let el = this.getSlotNode(data[i])

      let finalElIndex = data.length - 1 + i
      this.setSlotTransform(el, finalElIndex * this.slotAngle)

      this.newSlots.push(el)

      let clone = el.cloneNode(true)
      finalElIndex = data.length - 1 - i
      this.setSlotTransform(clone, finalElIndex * this.slotAngle)

      if (i !== 0) {
        this.newSlots.unshift(clone)
      }
    }

    this.midIndex = Math.floor(this.newSlots.length / 2)
  }

  /**
   * @function createNewPageSlots
   * Create slot html element by looping through data array and append or prepend them
   * Make sure to have mirror elements if page equals to 1
   * @param {array} data
   * @param {int} page
   * @param {String} direction
   */
  createNewPageSlots (data, page, machineDirection, pagingDirection, mixId = null) {
    let firstElAngle = 180 + this.slotAngle
    let lastElAngle = 180 - this.slotAngle

    if (this.slots.length) {
      let firstEl = this.slots[0]
      let lastEl = this.slots[this.slots.length - 1]

      firstElAngle = Utils.getRotationXValue(firstEl)
      lastElAngle = Utils.getRotationXValue(lastEl)
    }

    let tmpData = data
    if (pagingDirection === 'prev') {
      tmpData = data.slice().reverse()
    }

    for (let i = 0; i < tmpData.length; i++) {
      let el = this.getSlotNode(tmpData[i])

      if (mixId) {
        if (parseInt(el.dataset.mixId) === mixId) {
          el.classList.add('is-active')
        }
      }

      if (machineDirection === 'prev') {
        this.setSlotTransform(el, lastElAngle + this.slotAngle * (i + 1))
        this.ring.appendChild(el)
      } else if (machineDirection === 'next') {
        this.setSlotTransform(el, firstElAngle - this.slotAngle * (i + 1))
        this.ring.prepend(el)
      }
    }

    if (page === 1) {
      tmpData = data

      this.resetSlots()

      if (this.slots.length) {
        let firstEl = this.slots[0]
        let lastEl = this.slots[this.slots.length - 1]

        firstElAngle = Utils.getRotationXValue(firstEl)
        lastElAngle = Utils.getRotationXValue(lastEl)
      }

      if (mixId) { // Loading stored search
        for (let i = 1; i < tmpData.length; i++) {
          let el = this.getSlotNode(tmpData[i])

          if (machineDirection === 'prev') {
            this.setSlotTransform(el, firstElAngle - this.slotAngle * i)
            this.ring.prepend(el)
          } else if (machineDirection === 'next') {
            this.setSlotTransform(el, lastElAngle + this.slotAngle * i)
            this.ring.appendChild(el)
          }
        }
      } else {
        for (let i = 1; i < tmpData.length; i++) {
          let el = this.getSlotNode(tmpData[i])

          if (machineDirection === 'prev') {
            this.setSlotTransform(el, lastElAngle + this.slotAngle * i)
            this.ring.appendChild(el)
          } else if (machineDirection === 'next') {
            this.setSlotTransform(el, firstElAngle - this.slotAngle * i)
            this.ring.prepend(el)
          }
        }
      }
    }

    if (machineDirection === 'next') {
      if (page === 1) {
        this.selectedIndex += data.length * 2 - 1
      } else {
        this.selectedIndex += data.length
      }
    }

    // this.midIndex = Math.floor(nbEls / 2)
  }

  removePageSlots (direction, lastDirection, firstPageIsVisible, currentPage) {
    let nbSlotsToRemove = this.resultsPerPage

    if (firstPageIsVisible && lastDirection === direction && currentPage !== 1) {
      nbSlotsToRemove += this.resultsPerPage - 1
    }

    if (direction === 'next') {
      const reversedSlots = [...this.slots].reverse()
      Utils.forEach(reversedSlots, (index, slot) => {
        if (index < nbSlotsToRemove) {
          slot.parentNode.removeChild(slot)
        }
      })
    } else if (direction === 'prev') {
      Utils.forEach(this.slots, (index, slot) => {
        if (index < nbSlotsToRemove) {
          slot.parentNode.removeChild(slot)
        }
      })

      this.selectedIndex -= nbSlotsToRemove
    }

    this.resetSlots()
  }

  getSlotNode (dataEl) {
    let el = document.createElement('div')
    el.className = 'o-slot-square__el o-js-slot-el'
    el.setAttribute('data-mix-id', dataEl['id'])
    el.setAttribute('data-id', dataEl[this.identifier]['id'])

    let a = document.createElement('a')
    a.setAttribute('href', dataEl[this.identifier]['path'])

    let img = document.createElement('img')
    img.setAttribute('src', dataEl[this.identifier]['image'])
    img.setAttribute('alt', '')

    a.appendChild(img)
    el.appendChild(a)

    return el
  }

  /**
   * @function setSlotTransform
   * Set style.transform property to el
   * @param {node} el
   * @param {float} angle
   */
  setSlotTransform (el, angle) {
    let transform = 'rotateX(' + angle + 'deg) translateZ(' + this.getReelRadius() + 'px)'
    el.style.transform = transform
  }

  /**
   * @function appendNewSlotsInterval
   * Append slot with interval during ring loop animation
   * Only append slots that sould be displayed
   * @returns {Promise}
   */
  appendNewSlotsInterval () {
    return new Promise((resolve) => {
      let slotsToAppend = []
      this.newSlots.forEach((newSlot, index) => {
        if (this.slotShouldBeDisplayed(index)) {
          slotsToAppend.push(newSlot)
        }
      })

      this.ring.appendChild(slotsToAppend[0])
      let i = 1

      let interval = window.setInterval(() => {
        if (i < slotsToAppend.length) {
          this.ring.appendChild(slotsToAppend[i])
          i++
        } else {
          clearInterval(interval)
          resolve()
        }
      }, this.loopDuration / this.slotsPerReel * 1000)
    })
  }

  /**
   * @function appendAllNewSlots
   * Append all new slots without interval
   * Hide slot that shouldn't be displayed
   */
  appendAllNewSlots () {
    this.newSlots.forEach((newSlot, index) => {
      this.ring.appendChild(newSlot)

      if (!this.slotShouldBeDisplayed(index)) {
        newSlot.style.opacity = 0
      }
    })
  }

  /**
   * @function removeOldSlots
   * Remove all slot html elements
   * Reset slots @property
   */
  removeOldSlots () {
    Utils.forEach(this.slots, (index, slot) => {
      slot.parentNode.removeChild(slot)
    })
    this.slots = this.ring.querySelectorAll('.o-js-slot-el')
  }

  /**
   * @function onResize
   * Reset slotHeight @property on window resize
   * Call positionSlots @function
   */
  onResize () {
    if (this.ring.offsetHeight !== this.slotHeight) {
      this.slotHeight = this.ring.offsetHeight
      this.positionSlots()
    }
  }

  /**
   * @function setSlotsOpacityWithIndexes
   * @param {int} opacity - opacity to set
   * @param {array} indexes - indexes to set opacity to inside this.slots array
   */
  setSlotsOpacityWithIndexes (opacity, indexes) {
    for (let i = 0; i < indexes.length; i++) {
      if (this.slots[indexes[i]]) {
        this.slots[indexes[i]].style.opacity = opacity
      }
    }
  }

  setIndexesToShow () {
    let indexesToShow = []
    if (this.selectedIndex === 1) {
      for (let i = 0; i < this.slotsPerReel / 2; i++) {
        indexesToShow.push(this.selectedIndex + i)
      }
      for (let i = 1; i <= this.slotsPerReel / 2; i++) {
        indexesToShow.push(this.selectedIndex - i)
      }
    } else {
      for (let i = 1; i < this.slotsPerReel / 2; i++) {
        indexesToShow.push(this.selectedIndex + i)
      }
      for (let i = 0; i <= this.slotsPerReel / 2; i++) {
        indexesToShow.push(this.selectedIndex - i)
      }
    }

    this.hideAllSlots()
    this.setSlotsOpacityWithIndexes(1, indexesToShow)
  }

  /**
   * @function hideAllSlots
   * Set all slots to 0 opacity
   */
  hideAllSlots () {
    for (let i = 0; i < this.slots.length; i++) {
      this.slots[i].style.opacity = 0
    }
  }

  /**
   * @function resetSlots
   * Get new slots elements
   */
  resetSlots () {
    this.slots = this.ring.querySelectorAll('.o-js-slot-el')
  }
}
