import Utils from '../helpers/Utils'

export default class ShoppingList {
  constructor () {
    this.deleteCheckboxes = document.querySelectorAll('.o-js-shopping-checkbox input')
    this.deleteSubmit = document.querySelector('.o-js-shopping-submit')
  }

  init () {
    if (this.deleteSubmit) {
      this.handleEnableSubmit()

      Utils.forEach(this.deleteCheckboxes, (index, checkbox) => {
        checkbox.addEventListener('change', () => {
          this.handleEnableSubmit()
        })
      })
    }
  }

  handleEnableSubmit () {
    if (this.enableSubmit()) {
      this.deleteSubmit.removeAttribute('disabled')
    } else {
      this.deleteSubmit.setAttribute('disabled', 'disabled')
    }
  }

  enableSubmit() {
    let enable = false
    Utils.forEach(this.deleteCheckboxes, (index, checkbox) => {
      if (checkbox.checked) {
        enable = true
      }
    })

    return enable
  }
}
