import 'slick-carousel'
import MicroModal from 'micromodal'
import Utils from '../helpers/Utils'
import EventEmitter from '../helpers/EventEmitter'
import HistoryManager from '../helpers/HistoryManager'

export default class Modal {
  constructor (container = document) {
    this.triggers = container.querySelectorAll('[data-modal-trigger]')
    this.youtubeIds = {
      'fr': 'EzNcKBbGlZo',
      'en': 'w9d0YiRKq68'
    }
    this.openedModalIds = []
  }

  init () {
    this.addYoutubeScript()
    this.checkFullScreenChange()

    if (this.triggers) {
      Utils.forEach(this.triggers, (index, trigger) => {
        let id = trigger.dataset.modalTrigger

        if (trigger.classList.contains('o-js-modal_full_text')) {
          let triggerPrev = trigger.parentNode.querySelectorAll('p')
          let triggerNew = document.createElement('a')
          triggerNew.classList.add('o-sheet__content__more')
          triggerNew.setAttribute('href', '#')
          triggerNew.dataset.barbaPrevent = 'self'
          triggerNew.dataset.modalTrigger = id
          triggerNew.innerHTML = trigger.dataset.modalLabel

          for (let i = 0; i < triggerPrev.length; i++) {
            if (i + 1 === triggerPrev.length) {
              triggerPrev[i].appendChild(triggerNew)
            }
          }

          trigger = triggerNew
        }

        trigger.addEventListener('click', (e) => {
          e.preventDefault()
          let anchor = trigger.getAttribute('href')

          if (trigger.classList.contains('o-js-modal_definition')) {
            this.handleDefinitionModal(trigger)
          }

          if (trigger.classList.contains('o-js-modal_grape')) {
            this.handleGrapeModal(trigger)
          }

          if (trigger.dataset.modalTrigger === 'demo') {
            this.handleDemoModal(trigger)
          }

          MicroModal.show(id, {
            disableScroll: true,
            awaitCloseAnimation: true,
            onShow: (modal) => {
              if (anchor && anchor !== '#') {
                setTimeout(() => {
                  let modalTarget = modal.querySelector(anchor)
                  modalTarget.scrollIntoView()
                }, 50)
              }

              const urlReplace = '#' + id
              history.pushState(null, null, urlReplace)
              HistoryManager.openedModalIds.push(id)

              if (trigger.hasAttribute('data-places-criteria')) {
                EventEmitter.emit('showPlacesModal')
              }
            },
            onClose: (modal, element) => {
              const modalId = modal.getAttribute('id')
              this.removeModalIdFromArray(modalId)

              if (!HistoryManager.closingModalAfterBack) {
                window.history.back()
              }
              HistoryManager.closingModalAfterBack = false

              if (trigger.hasAttribute('data-places-criteria')) {
                EventEmitter.emit('closePlacesModal')
              }

              if (trigger.classList.contains('o-js-modal_grape')) {
                $('.o-js-sheet__header__grape_slider').slick('unslick')
              }

              if (trigger.hasAttribute('data-modal-trigger')) {
                if (modal.querySelector('iframe')) {
                  this.reinitDemoPlayer(modal)
                }
              }
            }
          })
        })

        // Open allergen modal on show recipes
        if (trigger.parentNode.classList.contains('o-js-allergen')) {
          this.handleAllergenModal(trigger, id)
        }
      })
    }
  }

  removeModalIdFromArray (id) {
    HistoryManager.openedModalIds.forEach((modalId, index) => {
      if (modalId === id) {
        HistoryManager.openedModalIds.splice(index, 1)
      }
    })
  }

  handleDefinitionModal (trigger) {
    let definitionName = trigger.dataset.definitionName
    let definitionContent = trigger.dataset.definitionContent

    document.querySelector('.o-js-definition_name').textContent = definitionName
    document.querySelector('.o-js-definition_content').textContent = definitionContent
  }

  handleGrapeModal (trigger) {
    let grapeTitle = trigger.dataset.title
    let grapeOrigin = trigger.dataset.origin
    let grapeDesc = trigger.dataset.desc

    document.querySelector('.o-js-grape-title').textContent = grapeTitle
    document.querySelector('.o-js-grape-origin').textContent = grapeOrigin
    document.querySelector('.o-js-grape-desc').textContent = grapeDesc

    // Images
    let grapeImg1 = trigger.dataset.img1
    let grapeImg2 = trigger.dataset.img2
    let imagesWrapper = document.querySelector('.o-js-sheet__header__grape_slider')
    let img1el = document.createElement('div')
    let img2el = document.createElement('div')

    imagesWrapper.innerHTML = ''
    img1el.classList.add('o-sheet__header__img-bg')
    img2el.classList.add('o-sheet__header__img-bg')
    img1el.style.backgroundImage = 'url(' + grapeImg1 + ')'
    imagesWrapper.appendChild(img1el)

    if (grapeImg2 !== '') {
      img2el.style.backgroundImage = 'url(' + grapeImg2 + ')'
      imagesWrapper.appendChild(img2el)
    }

    $('.o-js-sheet__header__grape_slider').slick({
      infinite: true,
      dots: true
    })
  }

  handleAllergenModal (trigger, id) {
    let allergenCookie = Utils.getCookie('allergen')
    if (allergenCookie !== 'false') {
      let definitionName = trigger.dataset.definitionName
      let definitionContent = trigger.dataset.definitionContent

      document.querySelector('.o-js-definition_name').textContent = definitionName
      document.querySelector('.o-js-definition_content').textContent = definitionContent

      MicroModal.show(id, {
        disableScroll: true,
        awaitCloseAnimation: true,
      })
    }
  }

  handleDemoModal (trigger) {
    let videoId = trigger.dataset.demoId

    if (videoId) {
      const player = new YT.Player('demo-player', {
        height: '315',
        width: '560',
        videoId: videoId,
        playerVars: {
          rel: 0
        },
        events: {
          'onReady': (e) => {
            this.launchDemoVideo(e)
          }
        }
      })
    }
  }

  launchDemoVideo (e) {
    const player = e.target
    let iframe = document.getElementById('demo-player')

    player.playVideo() // won't work on android
    let requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen

    if (!requestFullScreen) {
      iframe = $('#demo-player')
      requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen
    }

    if (requestFullScreen) {
      requestFullScreen.bind(iframe)()
    }
  }

  reinitDemoPlayer (modal) {
    modal.querySelector('iframe').remove()

    const div = document.createElement('div')
    div.classList.add('o-modal__iframe')
    div.id = 'demo-player'

    modal.querySelector('.o-container').appendChild(div)
  }

  checkFullScreenChange () {
    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        this.closeDemoPlayerWhenExitFullscreen()
      }
    }, false)

    document.addEventListener('msfullscreenchange', () => {
      if (!document.msFullscreenElement) {
        this.closeDemoPlayerWhenExitFullscreen()
      }
    }, false)

    document.addEventListener('mozfullscreenchange', () => {
      if (!document.mozFullScreen) {
        this.closeDemoPlayerWhenExitFullscreen()
      }
    }, false)

    document.addEventListener('webkitfullscreenchange', () => {
      if (!document.webkitIsFullScreen) {
        this.closeDemoPlayerWhenExitFullscreen()
      }
    }, false)
  }

  closeDemoPlayerWhenExitFullscreen () {
    if (document.getElementById('demo') && document.getElementById('demo').classList.contains('is-open')) {
      MicroModal.close('demo')
    }
  }

  addYoutubeScript () {
    const youtubeScript = document.createElement('script')
    youtubeScript.src = 'https://www.youtube.com/iframe_api'
    let firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(youtubeScript, firstScriptTag)
  }
}
