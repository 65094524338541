const { tarteaucitron } = require('exports-loader?exports=tarteaucitron!tarteaucitronjs/tarteaucitron')
global.tarteaucitron = tarteaucitron

const currentLanguage = document.documentElement.lang

require('tarteaucitronjs/lang/tarteaucitron.' + currentLanguage + '.js')
require('tarteaucitronjs/tarteaucitron.services.js')

const texts = {
  en: {
    alertBig: 'HandSomm uses edible cookes and gives you control over what you want to activate',
    acceptAll: ' Yes, I\'m happy',
    personalize: 'Manage my cookies'
  },
  fr: {
    alertBig: 'HandSomm utilise des cookies digestes et vous donne le contrôle sur ceux que vous souhaitez activer',
    acceptAll: 'OK, ça me va',
    personalize: 'Personnaliser'
  }
}

tarteaucitron.lang.alertBigPrivacy = texts[currentLanguage]['alertBig']
tarteaucitron.lang.acceptAll = texts[currentLanguage]['acceptAll']
tarteaucitron.lang.personalize = texts[currentLanguage]['personalize']

// fix opsone for strict mode
tarteaucitron.fixSelfXSS = function(html) {
  return html.toString().replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}

tarteaucitron.init({
  orientation: 'bottom',
  useExternalCss: true,
  useExternalJs: true,
  showAlertSmall: false,
  showIcon: false,
  DenyAllCta: false
})

window.addEventListener('load', function () {
  tarteaucitron.user.gtagUa = 'UA-164056287-1';
  (tarteaucitron.job = tarteaucitron.job || []).push('gtag')
})
