import MicroModal from 'micromodal'
import Utils from '../helpers/Utils'

export default class Prehome {
  constructor () {
    this.el = document.querySelector('.o-js-prehome')
    this.legalSubmitBtn = document.querySelector('.o-js-legal-submit')
    this.legalRemember = document.querySelector('.o-js-legal-remember')
  }

  init () {
    let legalAge = Utils.getCookie('ps-legal-age')
    let prehome = this.el

    if (legalAge === '') {
      MicroModal.show('legal-age', {
        disableScroll: true,
        awaitCloseAnimation: true
      })
    } else {
      prehome.classList.add('is-visible')
    }

    this.legalSubmitBtn.addEventListener('click', () => {
      if (this.legalRemember.checked) {
        Utils.setCookie('ps-legal-age', true, 365)
      }
      prehome.classList.add('is-visible')
      MicroModal.close('legal-age')
    })
  }
}
