export default class StickyNav {
  constructor () {
    this.el = document.querySelector('.o-js-sticky-nav')
    if (this.el) {
      this.navHeight = this.el.offsetHeight
    }

    this.delta = 0
    this.lastScrollY = 0
  }

  init () {
    this.listenToScrollOn(window)
  }

  /**
   * @function listenToScrollOn
   */
  listenToScrollOn(el) {
    let ticking = false
    el.addEventListener('scroll', this.requestScrollEvent.bind(this, el, ticking))
  }

  requestScrollEvent (el, ticking) {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        this.manageNavVisibility(el)

        ticking = false
      })
    }
    ticking = true
  }

  manageNavVisibility (el) {
    let scrollY = el.scrollY

    if (el !== window) {
      scrollY = el.scrollTop
    }

    if (Math.abs(this.lastScrollY - scrollY) <= this.delta) {
      return
    }

    if (scrollY > this.lastScrollY && scrollY > 0) {
      this.el.classList.add('is-hidden')
    } else {
      this.el.classList.remove('is-hidden')
    }

    this.lastScrollY = scrollY
  }
}
