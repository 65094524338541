import Utils from '../helpers/Utils'
import EventEmitter from '../helpers/EventEmitter'
import HistoryManager from '../helpers/HistoryManager'

export default class Menu {
  constructor () {
    this.el = document.querySelector('.o-js-menu')
    this.menuClose = document.querySelector('.o-js-menu__close')
    this.menuOpen = document.querySelector('.o-js-menu__open')
    this.links = document.querySelectorAll('.o-js-menu-link')

    this.tabBar = document.querySelector('.o-js-tab-bar')
    this.tabItems = document.querySelectorAll('.o-js-tab-item')
    this.tabLinks = document.querySelectorAll('.o-js-tab-link')
  }

  init () {
    this.calculateVhVar()
    window.addEventListener('resize', () => {
      this.calculateVhVar()
    })

    this.menuOpen.addEventListener('click', (e) => {
      e.preventDefault()

      this.el.classList.add('o-menu--open')
      history.pushState(null, null, '#menu')
    })

    this.menuClose.addEventListener('click', () => {
      this.el.classList.remove('o-menu--open')

      if (!HistoryManager.closingModalAfterBack) {
        window.history.back()
      }
      HistoryManager.closingModalAfterBack = false
    })

    this.handleTabItems()
    this.handleClickOnLink()
    this.handleClickOnTabLink()

    EventEmitter.on('addShopping', () => {
      document.querySelector('.o-js-tab-item[data-namespace="shopping"]').classList.add('is-full')
    })
    EventEmitter.on('addFavorite', () => {
      document.querySelector('.o-js-tab-item[data-namespace="favorites"]').classList.add('is-full')
    })

    EventEmitter.on('showTabBar', (namespace) => {
      document.querySelector('.o-js-tab-item[data-namespace="' + namespace + '"]').classList.add('is-active')
    })
  }

  initAfterPageChange () {
    this.handleTabItems()
    this.handleClickOnTabLink()
  }

  calculateVhVar (){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  handleTabItems () {
    Utils.forEach(this.tabItems, (index, item) => {
      item.addEventListener('click', () => {
        if (!item.classList.contains('is-active')) {
          if (document.querySelector('.o-js-tab-item.is-active')) {
            document.querySelector('.o-js-tab-item.is-active').classList.remove('is-active')
          }
          item.classList.add('is-active')
        }
      })
    })
  }

  handleClickOnTabLink () {
    Utils.forEach(this.tabLinks, (index, link) => {
      link.addEventListener('click', () => {
        const namespace = link.dataset.namespace
        const item = document.querySelector('.o-js-tab-item[data-namespace="' + namespace + '"]')
        if (!item.classList.contains('is-active')) {
          if (document.querySelector('.o-js-tab-item.is-active')) {
            document.querySelector('.o-js-tab-item.is-active').classList.remove('is-active')
          }
          item.classList.add('is-active')
        }
      })
    })
  }

  handleClickOnLink () {
    Utils.forEach(this.links, (index, link) => {
      link.addEventListener('click', () => {
        if (link.classList.contains('o-js-user_not_authenticated')) {
          Utils.setCookie('after_authenticate_path', window.location.pathname, 1)
        }
        this.el.classList.remove('o-menu--open')
      })
    })
  }
}
