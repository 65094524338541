import noUiSlider from 'nouislider'
import Utils from '../helpers/Utils'
import EventEmitter from '../helpers/EventEmitter'

export default class Range {
  constructor () {
    this.el = document.querySelector('.o-js-range')
  }

  init () {
    this.initSlider()
    this.handleMeltingState()
    this.handleRangeChange()
    this.resetSlider()
  }

  initSlider () {
    let tooltips = JSON.parse(this.el.dataset.collection)

    noUiSlider.create(this.el, {
      range: {
        'min': 0,
        'max': tooltips.length - 1
      },
      step: 1,
      start: [5, tooltips.length - 1],
      connect: true,
      orientation: 'horizontal',
      behaviour: 'tap-drag',
      tooltips: [
        { to: (value) => {
          return this.el.dataset.legendStart + '<br>' + tooltips[parseInt(value)]
        } },
        { to: (value) => {
          return this.el.dataset.legendEnd + '<br>' + tooltips[parseInt(value)]
        } }
      ],
      format: {
        to: (value) => {
          return parseInt(value)
        },
        from: (value) => {
          return parseInt(value)
        }
      }
    })

    const presenceTimeId = this.el.dataset.recipePresenceTimeId
    const maxTimeId = this.el.dataset.recipeMaxTimeId

    if (presenceTimeId && maxTimeId) {
      const value = [presenceTimeId, maxTimeId]
      this.setValue(value)
    }
  }

  getValue () {
    if (this.el) {
      const value = this.el.noUiSlider.get()

      return value
    }

    return null
  }

  setValue (value) {
    this.el.noUiSlider.set(value)
  }

  handleMeltingState () {
    this.el.noUiSlider.on('slide', function (values) {
      const handles = document.querySelectorAll('.noUi-handle')

      if (values[0] === values[1]) {
        Utils.forEach(handles, (index, handle) => {
          handle.classList.add('is-melting')
        })
      } else if (document.querySelector('.noUi-handle').classList.contains('is-melting')) {
        Utils.forEach(handles, (index, handle) => {
          handle.classList.remove('is-melting')
        })
      }
    })
  }

  handleRangeChange () {
    let ids = JSON.parse(this.el.dataset.ids)

    this.el.noUiSlider.on('change', function(labels, from, values) {
      document.querySelector('#search_recipe_presence_time_id').value = (ids[values[0]])
      document.querySelector('#search_recipe_max_time_id').value = (ids[values[1]])

      EventEmitter.emit('updateRange')
    })
  }

  resetSlider () {
    EventEmitter.on('resetSearch', () => {
      this.el.noUiSlider.reset()
      document.querySelector('#search_recipe_presence_time_id').value = ''
      document.querySelector('#search_recipe_max_time_id').value = ''
    })
  }
}
