import MicroModal from 'micromodal'

export default class shareForm {
  constructor () {
    this.el = document.querySelector('.o-js-share-confirm__form')
    this.language = document.querySelector('html').getAttribute('lang')

    this.errorMessages = {
      'en': {
        'emailIsInvalid': 'The email address is not valid',
        'emailIsEmpty': 'This field is required',
      },
      'fr': {
        'emailIsInvalid': 'L\'adresse email n\'est pas valide',
        'emailIsEmpty': 'Ce champ est obligatoire',
      }
    }
  }

  init () {
    if (this.el) {
      this.handleFormSubmission()
    }
  }

  handleFormSubmission () {
    this.el.addEventListener('submit', (e) => {
      e.preventDefault()

      const emailInput = this.el.querySelector('input[type="email"]')
      const messageInput = this.el.querySelector('textarea')

      if (this.emailIsValid(emailInput.value) && messageInput.validity.valid) {
        const request = new XMLHttpRequest()
        request.open('POST', this.el.getAttribute('action'))
        request.send(new FormData(this.el))

        request.onreadystatechange = function () {
          if (this.readyState === XMLHttpRequest.DONE) {
            if (this.status === 200 || this.status === 204) {
              MicroModal.close('share-form')
              MicroModal.show('share-form-confirmation')
            } else {
              // Erreur
              console.log('Status de la réponse: %d (%s)', this.status, this.statusText)
            }
          }
        }
      } else {
        let error = this.errorMessages[this.language].emailIsInvalid
        if (emailInput.validity.valueMissing) {
          error = this.errorMessages[this.language].emailIsEmpty
        }
        
        if (emailInput.parentNode.querySelector('.o-form__error')) {
          emailInput.parentNode.querySelector('.o-form__error').remove()
        }

        const errorEl = document.createElement('p')
        errorEl.classList.add('o-form__error')
        errorEl.textContent = error
        emailInput.parentNode.appendChild(errorEl)
      }
    })
  }

  emailIsValid (email) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRegex.test(email)) {
      return true
    }

    return false
  }
}
