import Utils from '../helpers/Utils'

export default class SelectGrape {
  constructor () {
    this.checkboxes = document.querySelectorAll('.o-js-grape-checkbox')
    this.btn = document.querySelector('.o-js-grape-btn')
  }

  init () {
    Utils.forEach(this.checkboxes, (index, checkbox) => {
      checkbox.addEventListener('click', () => {
        if (checkbox.checked) {
          const label = checkbox.nextElementSibling
          this.btn.innerText = label.innerText
          this.btn.classList.add('is-gold')
        } else {
          this.resetBtnText()
        }
      })
    })
  }

  resetBtnText () {
    this.btn.innerText = this.btn.dataset.text
    this.btn.classList.remove('is-gold')
  }
}
