import 'jquery-typeahead'

export default class Definitions {
  constructor () {
    this.el = document.querySelector('.o-js-definitions')
    this.autocompleteDefinitions = $('.o-js-autocomplete_definitions')
  }

  init () {
    this.handleAutocompleteDefinitions()
  }

  handleAutocompleteDefinitions () {
    let definitions = this.autocompleteDefinitions.data('definitions')
    let definitionsItems = $('.o-js-autocomplete_definitions-item')
    let definitionsEmptyMessage = $('.o-js-definitions_empty_message')

    this.autocompleteDefinitions.typeahead({
      dynamic: true,
      accent: true,
      filter: true,
      maxItem: false,
      hint: true,
      highlight: true,
      minLength: 1,
      source: {
        definitions: {
          data: definitions,
          display: 'name'
        }
      },
      callback: {
        onResult: function(node, query, result, resultCount, resultCountPerGroup) {
          if (resultCount > 0) {
            definitionsEmptyMessage.hide()
            let results = []

            for (var i = 0; i < result.length; i++) {
              results.push(result[i].id)
            }

            definitionsItems.each(function() {
              if ($.inArray($(this).data('id'), results) !== -1) {
                $(this).show()
              } else {
                $(this).hide()
              }
            })
          } else {
            if (query !== '') {
              definitionsItems.hide()
              definitionsEmptyMessage.show()
            }
          }
        },
        onCancel: function(node, event) {
          definitionsItems.each(function() {
            $(this).show()
          })
        }
      }
    })
  }
}
