import Utils from '../helpers/Utils'

export default class UniqueCheckboxes {
  constructor () {
    this.containers = document.querySelectorAll('.o-js-unique-checkboxes')
  }

  init () {
    Utils.forEach(this.containers, (index, container) => {
      let checkboxes = container.querySelectorAll('.o-js-unique-checkbox')
      Utils.forEach(checkboxes, (index, checkbox) => {
        if (checkbox.classList.contains('is-checked')) {
          checkbox.checked = true
        }
      })

      this.handleCheckboxes(container, checkboxes)
    })
  }

  handleCheckboxes (container, checkboxes) {
    Utils.forEach(checkboxes, (index, checkbox) => {
      checkbox.addEventListener('click', () => {
        if (!checkbox.classList.contains('is-checked')) {
          checkbox.classList.add('is-checked')
        } else {
          checkbox.classList.remove('is-checked')
        }

        Utils.forEach(container.querySelectorAll('.o-js-unique-checkbox:checked'), (j, checked) => {
          if (checked.value !== checkbox.value) {
            checked.checked = false
            checked.classList.remove('is-checked')
          }
        })
      })
    })
  }
}
