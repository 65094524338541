import 'slick-carousel'

export default class Slider {
  init () {
    // Sheet header slider
    $('.o-js-sheet__header__slider').slick({
      infinite: true,
      dots: true
    })
  }
}
