import Utils from '../helpers/Utils'

export default class Favorites {
  constructor () {
    this.el = document.querySelectorAll('.o-js-favorites_card')
    this.trashBtns = document.querySelectorAll('.o-js-toggle-delete-btn')
    this.deleteForms = document.querySelectorAll('.o-js-delete-favorites')
    this.checkboxBtns = document.querySelectorAll('.o-js-toggle-to-favorite')
    this.links = document.querySelectorAll('.o-js-favorite-link')
  }

  init () {
    this.toggleTrashBtns()
    this.toggleCheckboxBtns()
    this.handleClickOnLinks()
  }

  toggleTrashBtns () {
    Utils.forEach(this.trashBtns, (index, trashBtn) => {
      trashBtn.addEventListener('click', (e) => {
        e.preventDefault()
        let currentCard = trashBtn.parentNode.parentNode
        let currentDeleteForm = currentCard.querySelector('.o-js-delete-favorites')
        let currentDeleteSubmit = currentDeleteForm.querySelector('.o-js-delete-favorites__submit')

        // Deactivate this card's trash + delete favorites form
        if (currentCard.classList.contains('is-active')) {
          currentCard.classList.remove('is-active')
          currentDeleteForm.classList.remove('is-visible')
          currentDeleteSubmit.setAttribute('disabled', 'disabled')
        } else {
          // Activate this card's trash + delete favorites form & Deactivate the others
          Utils.forEach(this.el, (index, card) => {
            if (card !== currentCard) {
              card.classList.remove('is-active')
            }
          })
          currentCard.classList.add('is-active')

          Utils.forEach(this.deleteForms, (index, form) => {
            if (form !== currentDeleteForm) {
              let formDeleteSubmit = form.querySelector('.o-js-delete-favorites__submit')
              form.classList.remove('is-visible')
              formDeleteSubmit.setAttribute('disabled', 'disabled')
            }
          })
          currentDeleteForm.classList.add('is-visible')
        }

        // Deactivate all checkboxes
        Utils.forEach(this.checkboxBtns, (index, checkboxBtn) => {
          checkboxBtn.classList.remove('is-checked')
          checkboxBtn.nextElementSibling.removeAttribute('checked')
        })
      })
    })
  }

  toggleCheckboxBtns () {
    Utils.forEach(this.checkboxBtns, (index, checkboxBtn) => {
      checkboxBtn.addEventListener('click', (e) => {
        e.preventDefault()
        let currentCard = checkboxBtn.parentNode.parentNode.parentNode.parentNode
        let currentDeleteSubmit = currentCard.querySelector('.o-js-delete-favorites__submit')

        if (checkboxBtn.classList.contains('is-checked')) {
          checkboxBtn.classList.remove('is-checked')
          checkboxBtn.nextElementSibling.removeAttribute('checked')
        } else {
          checkboxBtn.classList.add('is-checked')
          checkboxBtn.nextElementSibling.setAttribute('checked', 'checked')
        }

        // Activate / deactivate delete forms submit
        this.toggleDeleteFormsSubmit(this.checkboxBtns, currentDeleteSubmit)
      })
    })
  }

  toggleDeleteFormsSubmit (checkboxBtns, currentDeleteSubmit) {
    let checkedCheckboxBtn = false

    Utils.forEach(checkboxBtns, (index, checkboxBtn) => {
      if (checkboxBtn.classList.contains('is-checked')) {
        checkedCheckboxBtn = true
      }
    })

    if (checkedCheckboxBtn) {
      currentDeleteSubmit.removeAttribute('disabled')
    } else {
      currentDeleteSubmit.setAttribute('disabled', 'disabled')
    }
  }

  handleClickOnLinks () {
    Utils.forEach(this.links, (index, link) => {
      link.addEventListener('click', (e) => {
        if (link.closest('.o-js-favorites_card.is-active')) {
          e.preventDefault()
        }
      })
    })
  }
}
