import EventEmitter from '../helpers/EventEmitter'
import Utils from '../helpers/Utils'

export default class shareButtons {
  constructor (container = document) {
    this.toggleFavoriteBtns = container.querySelectorAll('.o-js-add-favorite')
    this.toggleShoppingBtns = container.querySelectorAll('.o-js-add-shopping')
    this.userNotAuthenticatedBtns = container.querySelectorAll('.o-js-user_not_authenticated')
  }

  init () {
    this.handleFavoriteBtn()
    this.handleShoppingBtn()
  }

  handleFavoriteBtn() {
    if (this.toggleFavoriteBtns.length) {
      Utils.forEach(this.toggleFavoriteBtns, (index, btn) => {
        btn.addEventListener('ajax:success', () => {
          this.updateFavoriteIcons()

          if (btn.classList.contains('is-active')) {
            EventEmitter.emit('addFavorite')
          } else {
            EventEmitter.emit('removeFavorite')
          }
        })
      })
    }
  }

  updateFavoriteIcons () {
    Utils.forEach(this.toggleFavoriteBtns, (index, btn) => {
      btn.classList.toggle('is-active')
    })
  }

  handleShoppingBtn () {
    if (this.toggleShoppingBtns.length) {
      Utils.forEach(this.toggleShoppingBtns, (index, btn) => {
        btn.addEventListener('ajax:success', () => {
          this.updateShoppingIcons()

          if (btn.classList.contains('is-active')) {
            EventEmitter.emit('addShopping')
          } else {
            EventEmitter.emit('removeShopping')
          }
        })
      })
    }
  }

  updateShoppingIcons () {
    Utils.forEach(this.toggleShoppingBtns, (index, btn) => {
      btn.classList.toggle('is-active')
    })
  }
}
