/**
 * @file
 * HistoryManager Class
 *
 * Gathers several useful functions
 */

// https://www.sitepoint.com/javascript-design-patterns-singleton/

import MicroModal from 'micromodal'

class HistoryManager {
  constructor () {
    this.openedModalIds = []
    this.closingModalAfterBack = false
  }

  init () {
    this.listenToPopstate()
  }

  listenToPopstate () {
    window.onpopstate = () => {
      if (this.openedModalIds.length) {
        const lastOpenedModalId = this.openedModalIds[this.openedModalIds.length - 1]
        this.closingModalAfterBack = true
        MicroModal.close(lastOpenedModalId)
      } else if (document.querySelector('.o-js-menu').classList.contains('o-menu--open')) {
        document.querySelector('.o-js-menu').classList.remove('o-menu--open')
      }
    }
  }

  setClosingModalAfterBack (value) {
    this.closingModalAfterBack = value
  }
}

const instance = new HistoryManager()

export default instance
