/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery'
import '../utils/tarteaucitron'

import EventEmitter from '../src/helpers/EventEmitter'
import Utils from '../src/helpers/Utils'
import CommonEvents from '../src/helpers/CommonEvents'
import HistoryManager from '../src/helpers/HistoryManager'

import StickyNav from '../src/components/StickyNav'
import Range from '../src/components/Range'
import Modal from '../src/components/Modal'
import SlotMachine from '../src/components/SlotMachine'
import Menu from '../src/components/Menu'
import Transitions from '../src/components/Transitions'
import Map from '../src/components/Map'
import Slider from '../src/components/Slider'
import Comments from '../src/components/Comments'
import ShareButtons from '../src/components/ShareButtons'
import UniqueCheckboxes from '../src/components/UniqueCheckboxes'
import ToggleButtons from '../src/components/ToggleButtons'
import ShareForm from '../src/components/ShareForm'
import Icons from '../src/components/Icons'
import Forms from '../src/components/Forms'

import Prehome from '../src/pages/Prehome'
import ShoppingList from '../src/pages/ShoppingList'
import Regions from '../src/pages/Regions'
import Favorites from '../src/pages/Favorites'
import Definitions from '../src/pages/Definitions'

class App {
  constructor () {
    this.commonEvents = new CommonEvents()
    this.stickyNav = new StickyNav()
    this.range = new Range()
    this.slotMachine = new SlotMachine()
    this.modal = new Modal()
    this.menu = new Menu()
    this.transitions = new Transitions()
    this.map = new Map()
    this.slider = new Slider()
    this.favorites = new Favorites()
    this.comments = new Comments()
    this.shareButtons = new ShareButtons()
    this.uniqueCheckboxes = new UniqueCheckboxes()
    this.toggleButtons = new ToggleButtons()
    this.shareForm = new ShareForm()
    this.icons = new Icons()
    this.forms = new Forms()

    this.prehome = new Prehome()
    this.shoppingList = new ShoppingList()
    this.regions = new Regions()
    this.definitions = new Definitions()
  }

  init () {
    this.watchBrowserLanguage()
    this.listenToResize()

    HistoryManager.init()

    if (this.prehome.el) {
      this.prehome.init()
    }

    if (this.stickyNav.el) {
      this.stickyNav.init()
    }

    if (this.range.el) {
      this.range.init()
    }
    this.modal.init()

    if (this.slotMachine.el) {
      this.slotMachine.init()
    }

    if (this.menu.el) {
      this.menu.init()
    }

    this.transitions.init()

    if (this.map.el) {
      this.map.init()
    }

    if (this.regions.el) {
      this.regions.init()
    }

    if (this.definitions.el) {
      this.definitions.init()
    }

    if (this.favorites.el) {
      this.favorites.init()
    }

    if (this.shareForm.el) {
      this.shareForm.init()
    }

    this.icons.init()
    this.forms.init()
    this.slider.init()
    this.comments.init()
    this.shareButtons.init()
    this.uniqueCheckboxes.init()
    this.toggleButtons.init()
    this.shoppingList.init()
    this.commonEvents.init()

    this.reinitOnPageChange()
    this.handleTabBar()
    this.handleWindowHash()
  }

  reinitOnPageChange () {
    EventEmitter.on('reinitPage', (namespace, container) => {
      this.stickyNav = new StickyNav()
      if (this.stickyNav.el) {
        this.stickyNav.init()
      }

      this.menu = new Menu()
      if (this.menu.el) {
        this.menu.initAfterPageChange()
      }

      this.favorites = new Favorites()
      this.favorites.init()

      if (namespace === 'search') {
        this.reinitSearchElements(container)
      } else if (namespace === 'sheet') {
        this.reinitSheetElements(container)
      } else if (namespace === 'shopping') {
        this.reinitShoppingElements()
      } else if (namespace === 'regions') {
        this.regions = new Regions(container)
        this.regions.init()
      } else if (namespace === 'prehome') {
        this.prehome = new Prehome()
        this.prehome.init()
      }

      this.modal = new Modal(container)
      this.modal.init()

      this.commonEvents = new CommonEvents()
      this.commonEvents.init()

      this.shareForm = new ShareForm()
      if (this.shareForm.el) {
        this.shareForm.init()
      }

      this.forms = new Forms()
      this.forms.init()

      this.handleWindowHash()
    })
  }

  reinitSearchElements (container) {
    this.slotMachine = new SlotMachine()
    this.slotMachine.init()

    this.regions = new Regions(container)
    this.regions.init()
    if (this.slotMachine.hasSelectedPlace()) {
      this.regions.initAfterCriteriaSaved()
    }

    const rangeValue = this.range.getValue()
    this.range = new Range()
    if (this.range.el) {
      this.range.init()
    }
    if (rangeValue) {
      this.range.setValue(rangeValue)
    }

    this.uniqueCheckboxes = new UniqueCheckboxes()
    this.uniqueCheckboxes.init()

    this.shareButtons = new ShareButtons()
    this.shareButtons.init()

    this.toggleButtons = new ToggleButtons()
    this.toggleButtons.init()
  }

  reinitSheetElements (container) {
    this.map = new Map()

    if (this.map.el) {
      this.map.init()
    }

    this.comments = new Comments()
    this.comments.init()

    this.shareButtons = new ShareButtons(container)
    this.shareButtons.init()

    this.slider = new Slider()
    this.slider.init()

    this.icons = new Icons()
    this.icons.init()
  }

  reinitShoppingElements () {
    this.shoppingList = new ShoppingList()
    this.shoppingList.init()
  }

  handleTabBar() {
    EventEmitter.on('handleTabBar', (state) => {
      if (state === 'show') {
        this.menu.tabBar.classList.remove('is-hidden')
      } else if (state === 'hide') {
        this.menu.tabBar.classList.add('is-hidden')
      }
    })
  }

  listenToResize () {
    let ticking = false
    window.addEventListener('resize', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (this.slotMachine.el) {
            this.slotMachine.onResize()
          }

          ticking = false
        })
      }
      ticking = true
    })
  }

  handleWindowHash () {
    if (window.location.hash) {
      const id = window.location.hash.substr(1)
      const el = document.getElementById(id)
      if (el) {
        const elTop = el.getBoundingClientRect().top
        window.scroll(0, elTop)
      }
    }
  }

  watchBrowserLanguage () {
    let langSwitchCookie = Utils.getCookie('lang_switch')

    if (langSwitchCookie === '') {
      Utils.setCookie('lang_switch', true, 365)

      let userLang = navigator.language || navigator.userLanguage
      userLang = userLang.substring(0, 2)
      if (userLang !== 'en' && userLang !== 'fr') {
        userLang = 'en'
      }
      let userLangMeta = document.querySelector('meta[rel="alternate"][hreflang="' + userLang + '"]')
      let userLangPath = userLangMeta.getAttribute('href')

      window.location.href = userLangPath
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  var app = new App()
  app.init()
})

// TODO TEMP form

$(document).on('click', 'a.js-pin-recipe', function(e) {
  e.preventDefault()
  $('[name="search[recipe_id]"]').val($(this).data('id'))
})
$(document).on('click', 'a.js-pin-wine', function(e) {
  e.preventDefault()
  $('[name="search[wine_id]"]').val($(this).data('id'))
})
