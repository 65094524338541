import Utils from '../../helpers/Utils'
import EventEmitter from '../../helpers/EventEmitter'

export default class SelectedMix {
  constructor () {
    this.id = 0
    this.dataId = 0
    this.data = []
    this.language = document.querySelector('html').getAttribute('lang')

    this.setContainer()
    this.setHTMLElements()

    this.svgs = {
      'filledStar': '<svg class="is-filled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>',
      'unFilledStar': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg>',
      'unicorn': '<svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle stroke="#0D1A24" stroke-width="1.417" fill="#FFF" cx="17" cy="17" r="16.292"/><path d="M10.116 18.256c.971-4.462 2.714-7.3 5.801-8.214 3.261-.558 4.307-.735 4.307-.735l-.398 1.14S25.58 6.787 27 6c-1.17.989-3.98 4.986-5 6 .498.583 2.98 4.684 3.328 5.521.522.608.896 1.623.348 2.865-.398.913-.747 1.546-1.618 1.293-.847-.33-2.465-1.825-2.938-2.332-1.045-.812-1.32-1.065-2.066-.99 1.394 3.17 1.643 3.043 2.514 6.643H8c1.992-3.777 1.892-4.234 2.116-6.744z" fill="#0D1A24" fill-rule="nonzero"/></g></svg>',
      'candy': '<svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle stroke="#0D1A24" stroke-width="1.417" fill="#FFF" cx="17" cy="17" r="16.292"/><path d="M8.174 26.108l.123.496L6.685 30l-3.46-4.773 3.46-5.09-.124-.5 3.46-5.089 1.612 6.468-3.46 5.092zm9.5-12.473L16.339 8.28c2.013-.805 4.356-.625 6.473.675L20.59 13.5c-.667-.316-1.38-.397-2.062-.226-.306.076-.59.198-.854.362zm9.728 1.336l-5.35 1.334a3.006 3.006 0 0 0-.084-.92 3.103 3.103 0 0 0-1.131-1.708l2.957-4.148c2.114 1.283 3.363 3.278 3.608 5.442zm-4.625 2.739l5.056-1.26a9.066 9.066 0 0 1-1.103 4.26c-.65 1.19-2.095 1.55-3.228.805-1.133-.743-1.524-2.31-.873-3.498.054-.102.105-.202.148-.307zM14.84 8.59l1.253 5.027c-.068.082-.133.17-.195.26l-3.277 4.78-1.6-6.415.46-.671A9.51 9.51 0 0 1 14.84 8.59z" fill="#0D1A24" fill-rule="nonzero"/></g></svg>',
      'cock': '<svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle stroke="#0D1A24" stroke-width="1.417" fill="#FFF" cx="17" cy="17" r="16.292"/><path d="M28.392 18.914a4.175 4.175 0 0 0-1.085-2.645 4.175 4.175 0 0 0-2.517-1.333 6.166 6.166 0 0 1 4.765-.195 6.14 6.14 0 0 0-2.796-3.134 6.162 6.162 0 0 0-8.346 2.508c-.646 1.2-.879 2.453-.738 3.626a4.018 4.018 0 0 1-.934-.15c-1.907-.567-3.297-2.391-3.128-4.3l-.035.02c.018-.119.036-.238.036-.361 0-.135-.017-.265-.04-.394.419-.13.724-.515.724-.976 0-.473-.323-.868-.759-.987a1.027 1.027 0 0 0-.952-1.408c-.17 0-.327.045-.467.117a1.37 1.37 0 0 0-2.614.567c0 .415.19.783.482 1.034a2.396 2.396 0 0 0-.927 1.02H7.484a.168.168 0 0 0-.164.13.168.168 0 0 0 .088.19l1.414.707-1.645.822a.17.17 0 0 0-.093.17c.008.07.059.128.127.144l2.138.535c-.48.468-.92.987-1.056 1.209a1.026 1.026 0 0 0 .705 1.555c-.177.476.017 1.02.478 1.265.232.121.487.143.723.085a6.158 6.158 0 0 0 4.959 6.21l.509 2.037h1.37l.509-2.038a6.16 6.16 0 0 0 4.541-3.808c.277-.233.596-.422.962-.535a2.794 2.794 0 0 1 3.353 1.491 2.79 2.79 0 0 0-2.254-3.851 4.192 4.192 0 0 1 4.244.673z" fill="#0D1A24" fill-rule="nonzero"/></g></svg>'
    }
  }

  setContainer () {
    this.container = document.querySelector('.o-js-search-results')
  }

  setHTMLElements () {
    this.recipeInfo = {
      'title': document.querySelector('.o-js-recipe-title'),
      'difficulty': document.querySelector('.o-js-recipe-difficulty'),
      'price': document.querySelector('.o-js-recipe-price'),
      'tags': document.querySelector('.o-js-recipe-tags'),
      'tips': document.querySelector('.o-js-recipe-tips')
    }

    this.wineInfo = {
      'title': document.querySelector('.o-js-wine-title'),
      'price': document.querySelector('.o-js-wine-price'),
      'tags': document.querySelector('.o-js-wine-tags'),
      'delay': document.querySelector('.o-js-wine-delay'),
      'link': document.querySelector('.o-js-wine-link')
    }

    this.rateInfo = {
      'title': document.querySelector('.o-js-rate-title'),
      'stars': document.querySelector('.o-js-rate-stars')
    }

    this.favorite = document.querySelector('.o-js-add-favorite')
    this.shopping = document.querySelector('.o-js-add-shopping')
    this.shareForm = document.querySelector('.o-js-share-confirm__form')
  }

  hideResults () {
    this.container.classList.add('is-invisible')
  }

  showResults () {
    this.container.classList.remove('is-invisible')
  }

  updateInfo () {
    if (this.data) {
      this.updateRate()
      this.updateTitles()
      this.updatePrices()
      this.updateDifficulty()
      this.updateTags()
      this.updateTips()
      this.updateDelay()
      this.updateAdvices()
      if (this.favorite) {
        this.updateFavorite()
      }
      if (this.shopping) {
        this.updateShopping()
      }
      if (this.shareForm) {
        this.updateShareForm()
      }
    }
  }
  /**
   * @function updateRate
   */
  updateRate () {
    let nbStars = this.data.stars
    let stars = ''
    for (let i = 0; i < 4; i++) {
      stars += this.svgs.unFilledStar
    }
    this.rateInfo.stars.innerHTML = stars

    let showInterval = setInterval(() => {
      if (nbStars > 0) {
        let unFilledStars = this.rateInfo.stars.querySelectorAll('svg:not(.is-filled)')
        if (unFilledStars.length) {
          let nextEl = unFilledStars[0]
          nextEl.parentNode.replaceChild(document.createRange().createContextualFragment(this.svgs.filledStar), nextEl)
        }

        nbStars -= 1
      } else {
        clearInterval(showInterval)
        this.rateInfo.title.textContent = this.data.agreement_type
        EventEmitter.emit('updateRateComplete')
      }
    }, 50)
  }

  updateTitles () {
    this.recipeInfo.title.textContent = this.data['recipe']['short_name']
    this.wineInfo.title.textContent = this.data['wine']['short_name']
    if (this.data['wine']['vintage'] !== '') {
      this.wineInfo.title.textContent += (' ' + this.data['wine']['vintage'])
    }
    this.recipeInfo.title.setAttribute('href', this.data['recipe']['path'])
    this.wineInfo.title.setAttribute('href', this.data['wine']['path'] + '?agreement_weight=' + this.data['agreement_weight'])
  }

  updatePrices () {
    let recipePrice = this.data['recipe']['price']
    let winePrice = this.data['wine']['price']

    let recipePriceLevel = this.getPriceLevel(recipePrice)
    let winePriceLevel = this.getPriceLevel(winePrice)

    this.recipeInfo.price.classList.remove('has-level1', 'has-level2', 'has-level3', 'is-hidden')
    if (recipePriceLevel !== -1) {
      this.recipeInfo.price.classList.add('has-level' + recipePriceLevel)
    } else {
      this.recipeInfo.price.classList.add('is-hidden')
    }

    this.wineInfo.price.classList.remove('has-level1', 'has-level2', 'has-level3', 'is-hidden')
    if (winePriceLevel !== -1) {
      this.wineInfo.price.classList.add('has-level' + winePriceLevel)
    } else {
      this.wineInfo.price.classList.add('is-hidden')
    }
  }

  getPriceLevel (price) {
    let level = -1

    switch (price) {
      case '1':
        level = 1
        break
      case '2':
        level = 2
        break
      case '3':
        level = 3
        break
      default:
        level = -1
    }

    return level
  }

  updateDifficulty () {
    let difficulty = this.data['recipe']['difficulty']
    let level = this.getDifficultyLevel(difficulty)

    this.recipeInfo.difficulty.classList.remove('has-level1', 'has-level2', 'has-level3')
    this.recipeInfo.difficulty.classList.add('has-level' + level)
  }

  getDifficultyLevel (difficulty) {
    let level = 1

    switch (difficulty) {
      case '2':
        level = 2
        break
      case '3':
        level = 3
        break
      default:
        level = 1
        break
    }

    return level
  }

  updateTags () {
    let recipeTags = this.data['recipe']['tags']
    this.recipeInfo.tags.querySelectorAll('span').forEach((icon) => {
      icon.classList.add('o-hidden')
    })
    recipeTags.forEach((recipeTag) => {
      let icon = this.recipeInfo.tags.querySelector('span[data-tag="' + recipeTag + '"]')
      icon.classList.remove('o-hidden')
    })

    let wineTags = this.data['wine']['tags']
    this.wineInfo.tags.querySelectorAll('span').forEach((icon) => {
      icon.classList.add('o-hidden')
    })
    wineTags.forEach((wineTag) => {
      let icon = this.wineInfo.tags.querySelector('span[data-tag="' + wineTag + '"]')
      icon.classList.remove('o-hidden')
    })
  }

  updateTips () {
    this.recipeInfo.tips.textContent = this.data['agreement_service_tips']
  }

  updateDelay () {
    this.wineInfo.delay.textContent = (this.data['agreement_delay']) ? this.data['agreement_delay'] : ''
  }

  updateFavorite () {
    if (this.data.agreement_favorites) {
      this.favorite.classList.add('is-active')
    } else {
      this.favorite.classList.remove('is-active')
    }

    this.favorite.setAttribute('href', this.data.agreement_favorites_path)
  }

  updateShopping () {
    if (this.data.agreement_shopping) {
      this.shopping.classList.add('is-active')
    } else {
      this.shopping.classList.remove('is-active')
    }

    this.shopping.dataset.vintage = this.data['wine']['vintage']
    this.shopping.setAttribute('href', this.data.agreement_shopping_path)
  }

  updateShareForm () {
    this.shareForm.setAttribute('action', '/' + this.language + '/agreements/' + this.data['id'] + '/share')
  }

  updateAdvices () {
    let advicesTitle = document.querySelector('.o-js-home-results__advices__title')
    let advices = document.querySelector('.o-js-home-results__advices__icons')
    let inputWithChildren = document.querySelector('#AvecEnfants')
    let inputPregnant = document.querySelector('#Enceinte')
    let inputBreastfeeding = document.querySelector('#Allaitant')
    let inputHalal = document.querySelector('#Halal')
    let inputCasher = document.querySelector('#Casher')
    let inputMilk = document.querySelector('#Laitages')
    let inputNut = document.querySelector('#Noix')
    let inputGluten = document.querySelector('#Gluten')
    let inputMoonshine = document.querySelector('#EauDeVieAutres')
    let inputTvDinner = document.querySelector('#PlateauEcran')

    if (advices) {
      advices.querySelectorAll('span').forEach((icon) => {
        icon.classList.add('o-hidden')
      })
    }
    advicesTitle.classList.remove('is-active')

    Utils.setCookie('screen_alert', false, 1)
    if (inputTvDinner.checked) {
      Utils.setCookie('screen_alert', true, 1)
    }

    Utils.setCookie('allergen', false, 1)
    if (this.data.agreement_has_allergen) {
      if (inputMilk.checked || inputNut.checked || inputGluten.checked) {
        this.showAdviceIcon(advices, 'allergen', advicesTitle)
        Utils.setCookie('allergen', true, 1)
      }
    }

    Utils.setCookie('trou_normand', false, 1)
    if (this.data.agreement_ok_trou_normand && !inputPregnant.checked && !inputBreastfeeding.checked && !inputHalal.checked && !inputMoonshine.checked) {
      this.showAdviceIcon(advices, 'trou_normand', advicesTitle)
      Utils.setCookie('trou_normand', true, 1)
    }

    Utils.setCookie('chabrot', false, 1)
    if (this.data.agreement_ok_chabrot && !inputPregnant.checked && !inputBreastfeeding.checked && !inputHalal.checked && !inputMoonshine.checked) {
      this.showAdviceIcon(advices, 'chabrot', advicesTitle)
      Utils.setCookie('chabrot', true, 1)
    }

    if (this.data.agreement_ok_with_children && inputWithChildren.checked) {
      this.showAdviceIcon(advices, 'with_children', advicesTitle)
    }

    if (this.data.agreement_ok_pregnant && inputPregnant.checked) {
      this.showAdviceIcon(advices, 'pregnant', advicesTitle)
    }

    if (this.data.agreement_ok_breastfeeding && inputBreastfeeding.checked) {
      this.showAdviceIcon(advices, 'breastfeeding', advicesTitle)
    }

    if (this.data.agreement_is_halal && inputHalal.checked) {
      this.showAdviceIcon(advices, 'halal', advicesTitle)
    }

    if (this.data.agreement_is_casher && inputCasher.checked) {
      this.showAdviceIcon(advices, 'casher', advicesTitle)
    }
  }

  showAdviceIcon (advices, tag, advicesTitle) {
    let icon = advices.querySelector('span[data-tag="' + tag + '"]')
    icon.classList.remove('o-hidden')
    advicesTitle.classList.add('is-active')
  }
}
