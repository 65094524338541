import Utils from '../helpers/Utils'

export default class CommonEvents {
  constructor () {
    this.backBtns = document.querySelectorAll('.o-js-back_btn')
    this.alertNoticeBtns = document.querySelectorAll('.o-js-alert-close')
  }

  init () {
    if (window && window.history && window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }

    if (this.backBtns.length) {
      Utils.forEach(this.backBtns, (index, backBtn) => {
        backBtn.addEventListener('click', () => {
          if (backBtn.dataset.path) {
            window.location = backBtn.dataset.path
          } else {
            window.history.back()
          }
        })
      })
    }

    if (this.alertNoticeBtns.length) {
      Utils.forEach(this.alertNoticeBtns, (index, alertNoticeBtn) => {
        alertNoticeBtn.addEventListener('click', (e) => {
          e.preventDefault()
          alertNoticeBtn.parentNode.classList.add('o-hidden')
        })
      })
    }
  }
}
