import Utils from '../helpers/Utils'

export default class Comments {
  constructor () {
    this.rateForm = document.querySelector('.o-js-sheet__card__rate-form')
    this.rateFormWrapper = document.querySelector('.o-js-sheet__card__rate-wrapper')
    this.rateRadioBtns = document.querySelectorAll('.o-js-sheet__card__rate-radio input.radio_buttons')
    this.rateEditTrigger = document.querySelector('.o-js-open-rate_form')
    this.rateData = document.querySelector('.o-js-sheet-rate-data')

    this.svgs = {
      'filledStar': '<svg class="is-filled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>',
      'unFilledStar': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg>',
    }
  }

  init () {
    if (this.rateForm) {
      // Redirect user to login page if not logged in
      if (this.rateForm.classList.contains('o-js-user_has_to_log_in')) {
        this.redirectUser()
      }

      // Check / uncheck radio buttons
      Utils.forEach(this.rateRadioBtns, (index, rateRadioBtn) => {
        rateRadioBtn.addEventListener('click', () => {
          this.updateCheckedStars(index)
        })
      })

      // Rate form success
      this.handleRateSuccess()

      // Rate form error
      this.handleRateError()

      // Edit user rate
      this.editExistingRate()
    }
  }

  redirectUser () {
    let loginBtn = this.rateForm.querySelector('.o-js-log_in')
    Utils.forEach(this.rateForm.elements, (index, element) => {
      element.addEventListener('input', function() {
        Utils.setCookie('after_authenticate_path', window.location.pathname, 1)
        window.location = loginBtn.getAttribute('href')
      })
    })
  }

  handleRateSuccess () {
    this.rateForm.addEventListener('ajax:success', (e) => {
      const message = this.rateForm.querySelector('textarea').value
      const rateRaw = this.rateForm.querySelector('input[name="add[note]"]:checked')
      let stars = ''

      if (rateRaw === null) {
        for (let i = 0; i < 3; i++) {
          stars += this.svgs.unFilledStar
        }
      } else {
        const rate = parseInt(rateRaw.value)

        for (let i = 0; i < rate; i++) {
          stars += this.svgs.filledStar
        }
        const remainingStars = 3 - rate
        for (let i = 0; i < remainingStars; i++) {
          stars += this.svgs.unFilledStar
        }
      }

      this.rateData.querySelector('.o-js-sheet-rate-data-message').textContent = message
      this.rateData.querySelector('.o-rating').innerHTML = stars
      this.rateData.classList.remove('is-hidden')
      this.rateFormWrapper.classList.add('is-hidden')
    })
  }

  handleRateError () {
    this.rateForm.addEventListener('ajax:error', (e) => {
      if (e.detail[2].status === 400) {
        const data = e.detail[0]
        console.log(data)
        let errorNote = data.errors.note
        let errorMessage = data.errors.message
        let rateNote = document.querySelector('.o-js-sheet__card__rate-radio .form-group')
        let rateMessage = document.querySelector('.o-js-sheet__card__rate-message .form-group')
        let helpBlocks = document.querySelectorAll('.o-js-sheet__card__rate-form .help-block')

        Utils.forEach(helpBlocks, (index, helpBlock) => {
          helpBlock.parentNode.removeChild(helpBlock)
        })

        if (errorNote) {
          let noteErrorText = ''
          Utils.forEach(errorNote, (index, error) => {
            noteErrorText += error
            noteErrorText += '. '
          })

          let noteHelpBlock = document.createElement('span')
          noteHelpBlock.classList.add('help-block')
          noteHelpBlock.innerHTML = noteErrorText

          rateNote.classList.add('has-error')
          rateNote.appendChild(noteHelpBlock)
        }

        if (errorMessage) {
          let messageHelpBlock = document.createElement('span')
          messageHelpBlock.classList.add('help-block')
          messageHelpBlock.innerHTML = errorMessage[0]

          rateMessage.classList.add('has-error')
          rateMessage.appendChild(messageHelpBlock)
        }
      }

      if (e.detail[2].status === 302) {
        let rateFormError = document.querySelector('.o-js-rate_form-error')

        if (rateFormError) {
          rateFormError.classList.remove('o-hidden')
        }
      }
    })
  }

  editExistingRate () {
    if (this.rateEditTrigger) {
      this.rateEditTrigger.addEventListener('click', (e) => {
        e.preventDefault()
        console.log(this)

        this.rateData.classList.add('is-hidden')
        this.rateFormWrapper.classList.remove('is-hidden')

        Utils.forEach(this.rateRadioBtns, (index, rateRadioBtn) => {
          if (rateRadioBtn.getAttribute('checked') === 'checked') {
            this.updateCheckedStars(index)
          }
        })
      })
    }
  }

  updateCheckedStars (index) {
    if ((index + 1) === 1) {
      document.querySelector('.o-js-sheet__card__rate-radio input[value="2"].radio_buttons').classList.remove('is-checked')
    }
    if ((index + 1) >= 2) {
      document.querySelector('.o-js-sheet__card__rate-radio input[value="1"].radio_buttons').classList.add('is-checked')
    }
    if ((index + 1) === 3) {
      document.querySelector('.o-js-sheet__card__rate-radio input[value="2"].radio_buttons').classList.add('is-checked')
    }
  }
}
