import barba from '@barba/core'
import EventEmitter from '../helpers/EventEmitter'

export default class Transitions {
  constructor () {
    this.firstLoad = true
    this.namespaces = ['search', 'favorites', 'shopping', 'regions']
    this.container = document.querySelector('[data-barba="container"]')
  }

  init () {
    this.initBarba()
    this.setActiveItem()
  }

  initBarba () {
    const _this = this

    barba.init({
      cacheIgnore: true,
      transitions: [
        {
          name: 'basic',
          leave: function(data) {
            const done = this.async()
            done()
          },
          enter: function(data) {
            const done = this.async()
            data.current.container.remove()
            window.scroll(0, 0)
            done()
          }
        }
      ],
      views: [
        {
          namespace: 'search',
          afterEnter (data) {
            if (!_this.firstLoad) {
              EventEmitter.emit('reinitPage', 'search', data.next.container)
              EventEmitter.emit('handleTabBar', 'show')
              EventEmitter.emit('showTabBar', 'search')
            }
            _this.firstLoad = false
          },
        },
        {
          namespace: 'favorites',
          afterEnter (data) {
            if (!_this.firstLoad) {
              EventEmitter.emit('reinitPage', 'favorites', data.next.container)
              EventEmitter.emit('handleTabBar', 'show')
            }
            _this.firstLoad = false
          },
        },
        {
          namespace: 'shopping',
          afterEnter (data) {
            if (!_this.firstLoad) {
              EventEmitter.emit('reinitPage', 'shopping', data.next.container)
              EventEmitter.emit('handleTabBar', 'show')
            }
            _this.firstLoad = false
          },
        },
        {
          namespace: 'prehome',
          beforeEnter (data) {
            EventEmitter.emit('handleTabBar', 'hide')

            if (!_this.firstLoad) {
              EventEmitter.emit('reinitPage', 'prehome', data.next.container)
            }
            _this.firstLoad = false
          },
        },
        {
          namespace: 'sheet',
          afterEnter (data) {
            if (!_this.firstLoad) {
              EventEmitter.emit('reinitPage', 'sheet', data.next.container)

              if (data.next.container.querySelector('.o-js-back_btn')) {
                data.next.container.querySelector('.o-js-back_btn').classList.add('is-visible')
              }
            }
            _this.firstLoad = false
          },
        },
        {
          namespace: 'account',
          afterEnter (data) {
            if (!_this.firstLoad) {
              EventEmitter.emit('reinitPage', 'account', data.next.container)
            }
            _this.firstLoad = false
          },
        },
        {
          namespace: 'regions',
          afterEnter (data) {
            if (!_this.firstLoad) {
              EventEmitter.emit('reinitPage', 'regions', data.next.container)
            }
            _this.firstLoad = false
          },
        },
        {
          namespace: 'page',
          afterEnter (data) {
            if (!_this.firstLoad) {
              EventEmitter.emit('reinitPage', 'page', data.next.container)
            }
            _this.firstLoad = false
          },
        },
      ],
      prevent: ({ el, event, href }) => {
        const barbaNamespace = document.querySelector('main').dataset.barbaNamespace
        if (el.dataset.namespace === barbaNamespace) {
          return true
        }
      }
    })

    barba.hooks.after(() => {
      var path = (window.location.href).replace(window.location.origin, '').toLowerCase()

      window.dataLayer = window.dataLayer || []
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date())

      gtag('config', 'UA-164056287-1', {
        'page_path': path
      })
    })
  }

  setActiveItem () {
    let currentNamespace = this.container.dataset.barbaNamespace
    if (this.namespaces.indexOf(currentNamespace) !== -1) {
      let tabItem = document.querySelector('.o-js-tab-item[data-namespace="' + currentNamespace + '"]')
      if (tabItem) {
        tabItem.classList.add('is-active')
      }
    }
  }
}
