/**
 * @class Regions
 * Handle county selection and animations
 */

import { TweenMax } from 'gsap/TweenMax'
import MicroModal from 'micromodal'
import UniqueCheckboxes from '../components/UniqueCheckboxes'

import Utils from '../helpers/Utils'
import EventEmitter from '../helpers/EventEmitter'
import HistoryManager from '../helpers/HistoryManager'

const MAX_SELECTED_COUNTIES = 6

export default class Regions {
  constructor (container = null) {
    this.container = container
    if (!container) {
      this.container = document.querySelector('body')
    }

    this.el = this.container.querySelector('.o-js-regions')
    this.regions = this.container.querySelectorAll('.o-js-region')
    this.language = document.querySelector('html').getAttribute('lang')
    this.uniqueCheckboxes = new UniqueCheckboxes()

    this.countiesContainer = this.container.querySelector('.o-js-counties')
    this.countiesList = this.container.querySelector('.o-js-counties-list')
    this.countyShape = this.container.querySelector('.o-js-counties-svg')
    this.check = this.container.querySelector('.o-js-regions-check')
    this.labels = this.container.querySelector('.o-js-regions-labels')
    this.label = this.container.querySelector('.o-js-regions-label')
    this.subtitle = this.container.querySelector('.o-js-regions-subtitle')
    this.backLink = this.container.querySelector('.o-js-regions-back')
    this.clearCountyBtn = this.container.querySelector('.o-js-regions-clear')
    this.clearCriteriaBtn = this.container.querySelector('.o-js-regions-criteria-clear')

    this.showSearchRecipeBtn = this.container.querySelector('.o-js-show-search-recipe')
    this.modalRegionConfirmTitle = this.container.querySelector('.o-js-regions-confirm__title')
    this.regionalSearchSubmitBtn = document.querySelector('.o-js-search-btn')

    // Form
    this.getRecipesForm = this.container.querySelector('.o-js-get-recipes-by-places__form')
    this.submit = this.container.querySelector('.o-js-regions-submit')
    this.submitBtn = this.container.querySelector('.o-js-regions-submit-btn')
    this.submitCriteriaBtn = this.container.querySelector('.o-js-regions-submit-btn-criteria')
    this.submitPageBtn = this.container.querySelector('.o-js-regions-submit-btn-page')
    this.inputHidden = this.container.querySelector('.o-js-regions-select-hiddens')

    this.select = this.container.querySelector('.o-js-regions-select')
    if (this.select) {
      this.selectLabel = this.select.querySelector('label')
      this.selectEl = this.select.querySelector('select')
    }

    this.selectedRegion = null
    this.selectedCounties = []

    this.countyScale = 1
    this.countyTranslateX = 0
    this.countyTranslateY = 0
  }

  init () {
    this.handleSelect()
    this.handleClickOnRegion()
    this.handleClickOnBackLink()

    this.handleClickOnSubmitBtn()

    if (this.submitCriteriaBtn) {
      this.handleClickOnSubmitCriteriaBtn()
    }

    if (this.getRecipesForm) {
      this.handleClickOnSubmitPageBtn()
      this.handleClickOnShowSearchRecipeBtn()
    }

    if (this.inputHidden.querySelectorAll('input').length) {
      this.displaySelectedCounties()
    }

    // Handle clear selection
    EventEmitter.on('closePlacesModal', () => {
      if (!this.selectedCounties.length) {
        this.clearSelection()
      }
    })
    EventEmitter.on('clearPlacesCriteria', () => {
      this.clearSelection()
      this.inputHidden.innerHTML = ''
      document.querySelector('[data-places-criteria]').textContent = document.querySelector('[data-places-criteria]').dataset.text
    })
    EventEmitter.on('resetSearch', () => {
      this.clearSelection()
      this.inputHidden.innerHTML = ''
      this.clearCriteriaBtn.classList.remove('is-visible')
      document.querySelector('[data-places-criteria]').textContent = document.querySelector('[data-places-criteria]').dataset.text
    })
  }

  initAfterCriteriaSaved () {
    this.counties = document.querySelectorAll('.o-js-county')
    this.handleClickOnCounty()

    const labels = document.querySelectorAll('.o-js-regions-label')
    Utils.forEach(labels, (index, label) => {
      const county = document.querySelector('.o-js-county[data-id="' + label.dataset.county + '"]')
      label.querySelector('.o-js-regions-clear').addEventListener('click', () => {
        this.handleClearCounty(county)
      })
    })
  }

  /**
   * @function displaySelectedCounties
   * Display county which have been stored in previous search
   */
  displaySelectedCounties () {
    const input = this.inputHidden.querySelector('input')
    const countyNumber = parseInt(input.value)

    const county = document.querySelector('.o-js-county[data-id="' + countyNumber + '"]')
    const regionName = county.closest('svg').dataset.name
    const region = document.querySelector('.o-js-region[data-name="' + regionName + '"]')
    const svg = this.countiesList.querySelector('svg[data-name="' + regionName + '"]')

    this.countyShape.innerHTML = svg.outerHTML
    this.hideDomTitles()

    this.hideAllRegionsBut(region)
    this.animateRegionFrom(region)
    this.selectedRegion = region

    Utils.forEach(this.inputHidden.querySelectorAll('input'), (index, input) => {
      const county = document.querySelector('.o-js-county[data-id="' + parseInt(input.value) + '"]')
      this.handleAddCounty(county, true)
    })

    document.querySelector('[data-places-criteria]').textContent = this.getCountiesListString()
    if (!this.clearCriteriaBtn.classList.contains('is-visible')) {
      this.clearCriteriaBtn.classList.add('is-visible')
    }

    EventEmitter.on('showPlacesModal', () => {
      Utils.forEach(this.inputHidden.querySelectorAll('input'), (index, input) => {
        const county = document.querySelector('.o-js-county[data-id="' + parseInt(input.value) + '"]')
        this.cloneAndPositionCheck(county)
      })
    })
  }

  /**
   * @function handleClickOnRegion
   * Get corresponding county and append it to countyShape element
   * Call hideAllRegionsBut @function
   * Call animateRegionFrom @function
   * Set @property selectedRegion
   */
  handleClickOnRegion () {
    Utils.forEach(this.regions, (index, region) => {
      region.addEventListener('click', () => {
        const name = region.dataset.name
        const svg = this.countiesList.querySelector('svg[data-name="' + name + '"]')

        this.countyShape.innerHTML = svg.outerHTML

        this.hideDomTitles()
        this.hideAllRegionsBut(region)
        this.animateRegionFrom(region)
        this.selectedRegion = region

        if (region.classList.contains('o-js-region-dom')) {
          setTimeout(() => {
            const county = document.querySelector('.o-js-county[data-id="' + region.dataset.id + '"]')
            this.handleAddCounty(county)
          }, 800)
        }
      })
    })
  }

  handleClickOnRecipesCheckboxes (checkboxes) {
    Utils.forEach(checkboxes, (index, checkbox) => {
      checkbox.addEventListener('click', () => {
        let recipeId = checkbox.getAttribute('value')
        let recipeName = checkbox.nextElementSibling.textContent

        this.showSearchRecipeBtn.dataset.recipeId = recipeId
        this.modalRegionConfirmTitle.textContent = recipeName

        // Enable/disable modal recipes submit btn
        if (document.querySelectorAll('.o-js-unique-checkbox.is-checked').length > 0) {
          this.regionalSearchSubmitBtn.removeAttribute('disabled')
        } else {
          this.regionalSearchSubmitBtn.setAttribute('disabled', 'disabled')
        }
      })
    })
  }

  handleClickOnShowSearchRecipeBtn () {
    this.showSearchRecipeBtn.addEventListener('click', (e) => {
      e.preventDefault()
      let recipeId = this.showSearchRecipeBtn.dataset.recipeId
      let path = this.showSearchRecipeBtn.getAttribute('href')

      window.location = path + '?search[recipe_id]=' + recipeId
    })
  }

  handleClickOnSubmitCriteriaBtn () {
    this.submitCriteriaBtn.addEventListener('click', () => {
      document.querySelector('[data-places-criteria]').textContent = this.getCountiesListString()

      if (!this.clearCriteriaBtn.classList.contains('is-visible')) {
        this.clearCriteriaBtn.classList.add('is-visible')
      }
    })
  }

  /**
   * @function handleClickOnSubmitPageBtn
   * Get recipes by places
   * Fill modal with recipes data
   * Open modal
   */
  handleClickOnSubmitPageBtn () {
    this.submitPageBtn.addEventListener('click', () => {
      let request = new XMLHttpRequest()
      request.open('GET', `recipes/get_recipes_by_places.json?${Utils.serialize(this.getRecipesForm)}`, true)
      request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
          // Get recipes
          let recipes = JSON.parse(request.responseText)

          // Fill modal with recipes list
          let modalRecipesList = document.querySelector('.o-js-unique-checkboxes')
          const countiesBlock = document.querySelector('.o-js-counties-block')
          const nearbyBlock = countiesBlock.cloneNode(true)

          recipes.forEach((recipe, index) => {
            if (index === 0) {
              this.addRecipesToModal(recipe[1], countiesBlock)
            } else if (index === 1) {
              nearbyBlock.style.paddingTop = '10px'
              countiesBlock.parentNode.appendChild(nearbyBlock)
              nearbyBlock.querySelector('.o-js-counties-block-title').textContent = ((recipe[1].length > 0)) ? countiesBlock.dataset.nearbyTitle : ''
              this.addRecipesToModal(recipe[1], nearbyBlock, 'nearby')
            }
          })

          // Add counties names in recipes list subtitle
          document.querySelector('.o-js-list-counties').textContent = this.getCountiesListString()

          // Handle unique checkboxes
          let modalRecipesCheckboxes = countiesBlock.parentNode.querySelectorAll('.o-js-unique-checkbox')
          this.uniqueCheckboxes.handleCheckboxes(countiesBlock.parentNode, modalRecipesCheckboxes)
          this.handleClickOnRecipesCheckboxes(modalRecipesCheckboxes)

          // Open modal
          MicroModal.show('recipe-county-list', {
            onShow: () => {
              this.regionalSearchSubmitBtn = document.querySelector('.o-js-search-btn')

              const urlReplace = '#recipe-county-list'
              history.pushState(null, null, urlReplace)
              HistoryManager.openedModalIds.push('recipe-county-list')
            },
            onClose: () => {
              modalRecipesList.innerHTML = ''
              nearbyBlock.parentNode.removeChild(nearbyBlock)
              this.regionalSearchSubmitBtn.setAttribute('disabled', 'disabled')

              if (!HistoryManager.closingModalAfterBack) {
                window.history.back()
              }
              HistoryManager.closingModalAfterBack = false
            }
          })
        }
      }
      request.send()
    })
  }

  addRecipesToModal (recipes, container, prefix = '') {
    let modalRecipesList = container.querySelector('.o-js-unique-checkboxes')
    let emptyRecipesListMessage = document.querySelector('.o-js-recipe-list-empty')

    if (recipes.length > 0) {
      if (prefix === '') {
        emptyRecipesListMessage.classList.add('o-hidden')
      }

      modalRecipesList.classList.remove('o-hidden')
      Utils.forEach(recipes, (index, recipe) => {
        let recipeListItem = document.createElement('div')
        recipeListItem.classList.add('o-radios__item')

        let recipeListItemInput = document.createElement('input')
        recipeListItemInput.classList.add('o-radios__input')
        recipeListItemInput.classList.add('o-js-unique-checkbox')
        recipeListItemInput.setAttribute('type', 'checkbox')
        recipeListItemInput.setAttribute('value', recipe.id)
        recipeListItemInput.setAttribute('name', 'recipe')
        recipeListItemInput.setAttribute('id', prefix + 'recipe-' + recipe.id)

        let recipeListItemLabel = document.createElement('label')
        recipeListItemLabel.classList.add('o-radios__label')
        recipeListItemLabel.setAttribute('for', prefix + 'recipe-' + recipe.id)
        recipeListItemLabel.textContent = recipe.name_translations[this.language]

        recipeListItem.appendChild(recipeListItemInput)
        recipeListItem.appendChild(recipeListItemLabel)

        modalRecipesList.appendChild(recipeListItem)
      })
    } else {
      if (prefix === '') {
        modalRecipesList.classList.add('o-hidden')
        emptyRecipesListMessage.classList.remove('o-hidden')
      }
    }
  }

  /**
   * @function handleClickOnSubmitBtn
   * Update input hidden by calling updateInputHidden @function
   * If critera btn emit event submitPlacesCriteria
   */
  handleClickOnSubmitBtn () {
    this.submitBtn.addEventListener('click', () => {
      this.updateInputHidden()

      if (this.submitBtn.classList.contains('o-js-regions-submit-btn-criteria')) {
        EventEmitter.emit('submitPlacesCriteria')
      }
    })
  }

  /**
   * @function getCountiesListString
   * Get selected counties' names sepatated by a comma
   * @return {string}
   */
  getCountiesListString () {
    let value = ''
    this.selectedCounties.forEach((county) => {
      if (value !== '') {
        value += ', '
      }
      value += county.dataset.name
    })

    return value
  }

  /**
   * @function animateRegionFrom
   * Hide region
   * Set county to region's size and position
   * Animate county to its original size and position
   * @param {node} region
   */
  animateRegionFrom (region) {
    const svg = this.countyShape.querySelector('svg')
    const originalWidth = svg.getAttribute('width')
    const originalHeight = svg.getAttribute('height')

    const regionSizes = region.getBoundingClientRect()
    svg.setAttribute('width', regionSizes.width)
    svg.setAttribute('height', regionSizes.height)

    this.countyTranslateX = regionSizes.left - svg.getBoundingClientRect().left
    this.countyTranslateY = regionSizes.top - svg.getBoundingClientRect().top

    svg.setAttribute('transform', 'translate(' + this.countyTranslateX + ', ' + this.countyTranslateY + ')')
    this.countyScale = originalWidth / regionSizes.width

    this.countiesContainer.classList.add('is-visible')
    this.hideAllRegionsBut()
    this.counties = document.querySelectorAll('.o-js-county')
    this.handleClickOnCounty()
    this.subtitle.textContent = this.subtitle.dataset.textCounty
    this.select.classList.add('is-invisible')
    this.backLink.classList.add('is-visible')

    TweenMax.to(svg, 0.4, {
      scale: this.countyScale,
      x: 0,
      y: 0,
      delay: 0.3,
      onComplete: () => {
        svg.setAttribute('width', originalWidth)
        svg.setAttribute('height', originalHeight)
        svg.style.transform = ''
        svg.setAttribute('transform', '')
      }
    })
  }

  /**
   * @function hideAllRegionsBut
   * Add "is-invisible" class to all this.regions elements except selectedRegion
   * @param {node} selectedRegion
   * If @param is null hide all regions
   */
  hideAllRegionsBut (selectedRegion = null) {
    Utils.forEach(this.regions, (index, region) => {
      if ((selectedRegion === null) || (selectedRegion && region.dataset.name !== selectedRegion.dataset.name)) {
        region.classList.add('is-invisible')
      }
    })
  }

  /**
   * @function showAllRegionsBut
   * Remove "is-invisible" class to all this.regions elements except selectedRegion
   * @param {node} selectedRegion
   * If @param is null show all regions
   */
  showAllRegionsBut (selectedRegion = null) {
    Utils.forEach(this.regions, (index, region) => {
      if ((selectedRegion === null) || (selectedRegion && region.dataset.name !== selectedRegion.dataset.name)) {
        region.classList.remove('is-invisible')
      }
    })
  }

  /**
   * @function hideDomTitles
   * Add "is-invisible" class to .o-js-dom-title elements
   */
  hideDomTitles () {
    Utils.forEach(document.querySelectorAll('.o-js-dom-title'), (index, item) => {
      item.classList.add('is-invisible')
    })
  }

  /**
   * @function hideDomTitles
   * Remove "is-invisible" class to .o-js-dom-title elements
   */
  showDomTitles () {
    Utils.forEach(document.querySelectorAll('.o-js-dom-title'), (index, item) => {
      item.classList.remove('is-invisible')
    })
  }

  /**
   * @function handleClickOnCounty
   * Call handleAddCounty @function if MAX_SELECTED_COUNTIES is not reached and click on not active county
   * Call handleClearCounty @function if click on active county
   */
  handleClickOnCounty () {
    Utils.forEach(this.counties, (index, county) => {
      county.addEventListener('click', () => {
        if (!county.classList.contains('is-active')) {
          if (this.selectedCounties.length < MAX_SELECTED_COUNTIES) {
            this.handleAddCounty(county)
          }
        } else {
          this.handleClearCounty(county)
        }
      })
    })
  }

  /**
   * @function cloneAndPositionCheck
   * Clone check node
   * Position checkmark according to county datasets offsets
   * @param {node} county
   */
  cloneAndPositionCheck (county) {
    const countySize = county.getBoundingClientRect()
    const containerSize = this.countiesContainer.getBoundingClientRect()

    let offsetX = parseInt(county.dataset.offsetX)
    let offsetY = parseInt(county.dataset.offsetY)

    if (window.innerWidth >= 1024) {
      offsetX *= 1.5
      offsetY *= 1.5
    }

    const check = this.check.cloneNode(true)
    this.countiesContainer.appendChild(check)
    check.dataset.county = county.dataset.id
    check.style.top = countySize.top - containerSize.top + offsetY + 'px'
    check.style.left = countySize.left - containerSize.left + offsetX + 'px'
    check.classList.add('is-visible')
  }

  /**
   * @function removeCheck
   * Remove check corresponding to a county
   * @param {node} county
   */
  removeCheck (county) {
    const check = document.querySelector('.o-js-regions-check[data-county="' + county.dataset.id + '"]')
    check.parentNode.removeChild(check)
  }

  /**
   * @function addLabel
   * Add label corresponding to a county
   * Listen to click on its close icon
   * @param {node} county
   */
  addLabel (county) {
    const label = this.label.cloneNode(true)
    const countyLabel = county.dataset.name
    label.querySelector('.o-js-regions-label-text').textContent = countyLabel
    label.dataset.county = county.dataset.id
    this.labels.appendChild(label)
    label.classList.add('is-visible')
    label.querySelector('.o-js-regions-clear').addEventListener('click', () => {
      this.handleClearCounty(county)
    })
  }

  /**
   * @function removeCheck
   * Remove check corresponding to a county
   * @param {node} county
   */
  removeLabel (county) {
    const label = document.querySelector('.o-js-regions-label[data-county="' + county.dataset.id + '"]')
    label.parentNode.removeChild(label)
  }

  /**
   * @function handleSelect
   * Handle label visible when selecting a county in select el
   */
  handleSelect () {
    this.selectEl.addEventListener('change', () => {
      if (this.selectEl.value !== '') {
        this.selectLabel.classList.add('is-hidden')

        const selectedCountyShape = document.querySelector('.o-js-county[data-id="' + this.selectEl.value + '"]')
        const svg = selectedCountyShape.parentNode.parentNode
        const regionName = svg.dataset.name
        const region = document.querySelector('.o-js-region[data-name="' + regionName + '"]')

        this.countyShape.innerHTML = svg.outerHTML
        this.hideDomTitles()
        this.hideAllRegionsBut(region)
        this.animateRegionFrom(region)
        this.selectedRegion = region

        setTimeout(() => {
          const county = document.querySelector('.o-js-county[data-id="' + this.selectEl.value + '"]')
          this.handleAddCounty(county)
        }, 800)
      } else if (this.selectLabel.classList.contains('is-hidden')) {
        this.selectLabel.classList.remove('is-hidden')
      }
    })
  }

  /**
   * @function handleClickOnBackLink
   * Reverse animation from county to region
   * Animate county to region's size and position
   * Show select, hide backLink
   */
  handleClickOnBackLink () {
    this.backLink.addEventListener('click', (e) => {
      e.preventDefault()

      const county = this.countyShape.querySelector('svg')
      const scale = 1 / this.countyScale

      county.style.transform = 'scale(' + scale + ')'
      const countySizes = county.getBoundingClientRect()
      const regionsSizes = this.selectedRegion.getBoundingClientRect()
      county.style.transform = ''

      const translateX = regionsSizes.left - countySizes.left
      const translateY = regionsSizes.top - countySizes.top

      this.showDomTitles()
      this.showAllRegionsBut(this.selectedRegion)
      this.subtitle.textContent = this.subtitle.dataset.textDefault
      this.select.classList.remove('is-invisible')
      this.select.querySelector('select').value = ''
      this.backLink.classList.remove('is-visible')

      TweenMax.fromTo(county, 0.4, {
        scale: 1
      }, {
        scale: scale,
        x: translateX,
        y: translateY,
        onComplete: () => {
          this.countiesContainer.classList.remove('is-visible')
          this.showAllRegionsBut()
        }
      })
    })
  }

  /**
   * @function clearSelection
   * Clear region and/or county selection when closing modal
   * Reset elements visibility
   */
  clearSelection () {
    this.selectedRegion = null
    this.selectedCounties = []
    this.subtitle.textContent = this.subtitle.dataset.textDefault
    this.select.classList.remove('is-invisible')
    this.backLink.classList.remove('is-visible')
    this.showDomTitles()
    this.showAllRegionsBut()
    this.countiesContainer.classList.remove('is-visible')
    this.countyShape.innerHTML = ''
    this.submit.classList.remove('is-visible')
    this.check.classList.remove('is-visible')

    Utils.forEach(document.querySelectorAll('.o-js-regions-label'), (index, label) => {
      if (label.classList.contains('is-visible')) {
        label.parentNode.removeChild(label)
      }
    })
    Utils.forEach(document.querySelectorAll('.o-js-regions-check'), (index, check) => {
      if (check.classList.contains('is-visible')) {
        check.parentNode.removeChild(check)
      }
    })
  }

  handleAddCounty (county, noClone = false) {
    this.selectedCounties.push(county)
    if (!noClone) {
      this.cloneAndPositionCheck(county)
    }
    this.addLabel(county)

    county.classList.add('is-active')

    if (!this.submit.classList.contains('is-visible')) {
      this.submit.classList.add('is-visible')
      this.subtitle.textContent = this.subtitle.dataset.textSelected
    }
  }

  handleClearCounty (county) {
    const elIndex = this.selectedCounties.indexOf(county)
    this.selectedCounties.splice(elIndex, 1)
    this.removeCheck(county)
    this.removeLabel(county)
    county.classList.remove('is-active')

    if (this.selectedCounties.length === 0) {
      this.subtitle.textContent = this.subtitle.dataset.textCounty
      this.submit.classList.remove('is-visible')
      this.backLink.classList.add('is-visible')
    }
  }

  updateInputHidden () {
    this.inputHidden.innerHTML = ''
    this.selectedCounties.forEach((county) => {
      let hiddenField = document.createElement('input')
      hiddenField.setAttribute('name', 'search[recipe_place_ids][]')
      hiddenField.setAttribute('value', county.dataset.id)

      this.inputHidden.appendChild(hiddenField)
    })
  }
}
