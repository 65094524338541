/**
 * @file
 * Utils Class
 * Used as a Singleton
 *
 * Gathers several useful functions
 */

class Utils {
  /**
   * @function forEach
   * Used to loop and NodeList (querySelectorAll items)
   */
  forEach (array, callback, scope) {
    if (array) {
      for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i])
      }
    }
  }

  getRotationXValue (el) {
    const regex = /rotateX\((?<radius>-?\d+)deg\)/

    let match = el.style.transform.match(regex)
    let angle = 0
    if (match !== null) {
      angle = parseInt(match.groups.radius)
    }

    return angle
  }

  index (el) {
    if (el) {
      return [...el.parentNode.children].indexOf(el)
    }

    return -1
  }

  toggleClass (el, className) {
    if (el.classList.contains(className)) {
      el.classList.remove(className)
    } else {
      el.classList.add(className)
    }
  }

  /**
   * @function findGetParameter
   * Used to find a GET parameter in url
   */
  findGetParameter (parameterName) {
    let result = null
    let tmp = []
    location.search.substr(1).split('&').forEach((item) => {
      tmp = item.split('=')
      if (decodeURI(tmp[0]) === parameterName) {
        result = decodeURIComponent(tmp[1])
      }
    })

    return result
  }

  serialize (form) {
    const serialized = []

    // Loop through each field in the form
    for (let i = 0; i < form.elements.length; i++) {
      const field = form.elements[i]

      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue

      // If a multi-select, get all selections
      if (field.type === 'select-multiple') {
        for (let n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue
          serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[n].value))
        }
      } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) { // Convert field data to a query string
        serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value))
      }
    }
    return serialized.join('&')
  }

  setCookie(cname, cvalue, exdays) {
    let d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }

  getCookie(cname) {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
}

const instance = new Utils()
Object.freeze(instance)

export default instance
